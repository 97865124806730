import { TICKET_STATUS } from './components/Modal Ticket/Follow Up/FollowUp';
import { MISSIONS_STATUS } from './data';

/**
 * Get the status of a ticket
 * @param {Object} ticket - The ticket object
 * @param {string} agencyId - The agency ID
 * @returns {string} The status of the ticket
 */
export function getTicketStatus(ticket, agencyId) {
	let status = TICKET_STATUS.inProgress;

	let agency = agencyId ?? 'clients';

	if (!ticket?.companiesViewed?.includes(agency)) {
		status = TICKET_STATUS.new;
	} else if (!ticket?.properties?.companies?.some((company) => ticket?.companiesViewed?.includes(company))) {
		status = TICKET_STATUS.pendingProcess;
	} else if (ticket?.issueStatus === TICKET_STATUS.pendingApproval) {
		status = ticket?.companiesReviewed?.includes(agency) ? TICKET_STATUS.inProgress : TICKET_STATUS.pendingApproval;
	} else if (ticket?.issueStatus === TICKET_STATUS.refused) {
		status =
			ticket?.properties?.companies?.includes(agency) || !ticket?.companiesViewedRefused?.includes(agency)
				? TICKET_STATUS.refused
				: TICKET_STATUS.inProgress;
	} else {
		status = ticket?.issueStatus ?? TICKET_STATUS.inProgress;
	}

	return status;
}

/**
 * Get the percentage of a mission
 * @param {Object} ticket - The ticket object
 * @returns {number} The percentage of the mission
 */
export function getMissionPercentage(ticket) {
	return ticket.missionStatus === MISSIONS_STATUS.finished
		? 100
		: ticket.todos?.length > 0
			? (ticket.todos?.reduce(
					(acc, todo) =>
						acc +
						(todo.completed
							? 1
							: Math.min(todo.subTodos.filter((subTodo) => subTodo.completed).length) /
								(todo.subTodos.length + 1)),
					0
				) /
					ticket.todos?.length) *
				100
			: 0;
}

export function getMissionStatus(ticket) {
	const percentage = getMissionPercentage(ticket);

	if (ticket.missionStatus === MISSIONS_STATUS.finished) return MISSIONS_STATUS.finished;
	if (percentage === 0) return MISSIONS_STATUS.new;
	return MISSIONS_STATUS.ongoing;
}
