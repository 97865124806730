import React, { useContext } from 'react';
import './_subPageSettingsProject.scss';
import InformationsGroup from './components/InformationsGroup';
import { useProjectContext } from 'src/contexts/projectContext';
import AgencyDataContext from 'src/contexts/agencyDataContext';
import { usePartnersContext } from 'src/contexts/partnersContext';
import { useTranslation } from 'react-i18next';

export default function Informations() {
	const { t } = useTranslation();

	const [project] = useProjectContext();
	const agencyData = useContext(AgencyDataContext);
	const [partners] = usePartnersContext();

	const dataPublicInformations = [
		{
			name: t('translation.project'),
			informations: [
				{
					name: t('translation.projectDescription'),
					value: project.projectDescription,
				},
				{
					name: t('translation.opusMandatoryCompany'),
					value:
						project?.creator === agencyData?.id
							? agencyData?.name
							: partners?.find((partner) => partner.id === project.mandataireId)?.name || '',
				},
				{
					name: t('project_settings.project_address'),
					value: project.address,
				},
			],
		},

		{
			name: t('translation.mo'),
			informations: [
				{
					name: t('translation.mo'),
					value: project.clientName,
				},
				{
					name: t('translation.assistMo'),
					value: project.assistClientName,
				},
			],
		},

		{
			name: t('translation.operation'),
			informations: [
				{
					name: t('translation.priceWithoutTax'),
					value: project.workAmount,
				},
				{
					name: t('translation.receptionDate'),
					value: project.receptionDate,
				},
				{
					name: t('translation.workOnSiteDuration'),
					value: project.worksDuration,
				},
			],
		},
	];

	const dataConfidentialInformations = [
		{
			name: t('translation.internal'),
			informations: [
				{
					name: t('translation.designatedCollab'),
					value: project.referentCollaborator,
				},
				{
					name: t('translation.missionScope'),
					value: project.missionFramework,
				},
				{
					name: t('translation.riskLevel'),
					value: project.risk,
				},
			],
		},

		{
			name: t('translation.revenue'),
			informations: [
				{
					name: t('translation.estimatedFees'),
					value: project.estimatedFees,
				},
				{
					name: t('translation.billedFees'),
					value: project.billedFees,
				},
				{
					name: t('translation.contractInformations'),
					value: project.contractSignatureDate,
				},
			],
		},
	];

	return (
		<div className={'subPageSettingsProject'}>
			<p className={'titleSubPage'}>{t('translation.publicInformations')}</p>
			<InformationsGroup data={dataPublicInformations} isPrivate={false} />

			<p className={'titleSubPage'}>{t('translation.privateInformations')}</p>
			<InformationsGroup data={dataConfidentialInformations} isPrivate={true} />
		</div>
	);
}
