import React, { useMemo, useContext } from 'react';
import './_ticketsColumn.scss';
import plusSquare from '../../../../../assets/plusSquare.svg';
import upDownGrey from '../../../../../assets/upDownGrey.svg';
import upDownBlue from '../../../../../assets/upDownBlue.svg';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TICKET_STATUS } from '../Modal Ticket/Follow Up/FollowUp';
import AgencyContext from '../../../../../contexts/agencyContext';
import TicketCard from '../Ticket Card/TicketCard';
import { getMissionPercentage, getTicketStatus } from '../../utils';
import { MISSIONS_STATUS } from '../../data';

export default function TicketsColumn({
	sortedTickets,
	type,
	handleCreateTicket,
	handleSelectTicket,
	isSnagging,
	activeSubPage,
	scrollLeft,
	filterSortOpened,
}) {
	const { t } = useTranslation();

	const agencyId = useContext(AgencyContext);

	const snagGroups = useMemo(
		() => [
			{
				label: t('translation.newSnags'),
				color1: '#91918E',
				color2: '#E3E2E0',
				color3: '#FCFCFA',
				tickets:
					sortedTickets.filter((ticket) => getTicketStatus(ticket, agencyId) === TICKET_STATUS.new) ?? [],
			},
			{
				label: t('translation.pending'),
				color1: '#5B97BD',
				color2: '#D3E5EF',
				color3: '#F5FAFC',
				tickets:
					sortedTickets.filter(
						(ticket) =>
							!(
								getTicketStatus(ticket, agencyId) === TICKET_STATUS.new ||
								(getTicketStatus(ticket, agencyId) === TICKET_STATUS.pendingApproval &&
									ticket?.properties?.reviewers?.includes(agencyId)) ||
								((getTicketStatus(ticket, agencyId) === TICKET_STATUS.refused ||
									getTicketStatus(ticket, agencyId) === TICKET_STATUS.inProgress) &&
									ticket?.properties?.companies?.includes(agencyId)) ||
								getTicketStatus(ticket, agencyId) === TICKET_STATUS.approved
							)
					) ?? [],
			},
			{
				label: t('translation.actionRequired'),
				color1: '#D7813A',
				color2: '#FADEC9',
				color3: '#FDF8F6',
				tickets:
					sortedTickets.filter(
						(ticket) =>
							(getTicketStatus(ticket, agencyId) === TICKET_STATUS.pendingApproval &&
								ticket?.properties?.reviewers?.includes(agencyId)) ||
							((getTicketStatus(ticket, agencyId) === TICKET_STATUS.refused ||
								getTicketStatus(ticket, agencyId) === TICKET_STATUS.inProgress) &&
								ticket?.properties?.companies?.includes(agencyId))
					) ?? [],
			},
			{
				label: t('translation.resolvedSnags'),
				color1: '#6C9B7D',
				color2: '#DBEDDB',
				color3: '#F8FAF7',
				tickets:
					sortedTickets.filter((ticket) => getTicketStatus(ticket, agencyId) === TICKET_STATUS.approved) ??
					[],
			},
		],
		[sortedTickets, agencyId]
	);

	const missionsGroups = [
		{
			label: t('translation.notStarted'),
			color1: '#91918E',
			color2: '#E3E2E0',
			color3: '#FCFCFA',
			tickets: sortedTickets.filter((ticket) => getMissionPercentage(ticket) === 0) ?? [],
		},
		{
			label: t('translation.inProgress'),
			color1: '#5B97BD',
			color2: '#D3E5EF',
			color3: '#F5FAFC',
			tickets:
				sortedTickets.filter(
					(ticket) => ticket.missionStatus !== MISSIONS_STATUS.finished && getMissionPercentage(ticket) > 0
				) ?? [],
		},
		{
			label: t('translation.finished'),
			color1: '#6C9B7D',
			color2: '#DBEDDB',
			color3: '#F8FAF7',
			tickets: sortedTickets.filter((ticket) => ticket.missionStatus === MISSIONS_STATUS.finished) ?? [],
		},
	];

	return (
		<div
			className={'ticketsColumn'}
			style={{
				flexDirection: activeSubPage === 'gallery' ? 'row' : 'column',
				gap: activeSubPage === 'gallery' ? 20 : 0,
				marginTop:
					activeSubPage === 'gallery'
						? filterSortOpened
							? 5
							: 20
						: activeSubPage === 'board'
							? 10
							: filterSortOpened
								? 0
								: 10,
			}}>
			{activeSubPage === 'board' ? (
				<div className={'ticketBoard'}>
					{(isSnagging ? snagGroups : missionsGroups).map((group) => (
						<div className={'board'} style={{ backgroundColor: group.color3 }} key={group.label}>
							<div
								className={'containerHeaderBoard'}
								style={activeSubPage === 'board' && filterSortOpened ? { paddingTop: 5 } : {}}>
								<div className={'headerBoard'} style={{ backgroundColor: group.color3 }}>
									<div className={'tagBoard'} style={{ backgroundColor: group.color2 }}>
										<div className={'rond'} style={{ backgroundColor: group.color1 }} />
										{group.label}
									</div>
								</div>
							</div>

							<div className={'containerCards'}>
								{group?.tickets?.map((ticket) => (
									<div key={ticket.id} onClick={() => handleSelectTicket(ticket.id)}>
										<TicketCard
											ticket={ticket}
											isSnagging={isSnagging}
											board={true}
											index={ticket.index}
										/>
									</div>
								))}

								{group?.tickets?.length === 0 && (
									<div className={'placeholderNoTickets'}>{t('translation.noSnags')}</div>
								)}
							</div>
						</div>
					))}
				</div>
			) : (
				sortedTickets.map((ticket) => (
					<div key={ticket.id} onClick={() => handleSelectTicket(ticket.id)}>
						{activeSubPage === 'gallery' ? (
							<TicketCard ticket={ticket} isSnagging={isSnagging} gallery={true} index={ticket.index} />
						) : activeSubPage === 'table' ? (
							<TicketCard
								ticket={ticket}
								isSnagging={isSnagging}
								index={ticket.index}
								scrollLeft={scrollLeft}
							/>
						) : null}
					</div>
				))
			)}
			{sortedTickets?.length === 0 && (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						height: 'calc(100vh - 400px)',
					}}>
					<div className={'buttonNewTicket'} onClick={() => handleCreateTicket(type)}>
						<img src={plusSquare} alt="" />
						<div>{isSnagging ? t('translation.createANewTicket') : t('translation.createNewMission')}</div>
					</div>
				</div>
			)}
		</div>
	);
}

function SortOption({ name, label, isSelected, setSortChoice, sortUp, setSortUp }) {
	return (
		<div
			className={isSelected ? 'sortOption sortOptionSelected' : 'sortOption hover'}
			onClick={() => {
				setSortChoice(label);
				setSortUp((prev) => !prev);
			}}>
			{name}
			<div style={{ width: 18, display: 'flex' }}>
				<img
					src={isSelected ? upDownBlue : upDownGrey}
					alt=""
					style={{ transform: isSelected && sortUp && 'rotate(180deg)' }}
				/>
			</div>
		</div>
	);
}

TicketsColumn.propTypes = {
	isSnagging: PropTypes.bool.isRequired,
	sortedTickets: PropTypes.array.isRequired,
	type: PropTypes.string.isRequired,
	handleCreateTicket: PropTypes.func.isRequired,
	handleSelectTicket: PropTypes.func.isRequired,
	activeSubPage: PropTypes.string.isRequired,
	scrollLeft: PropTypes.number,
	filterSortOpened: PropTypes.bool.isRequired,
};

SortOption.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	isSelected: PropTypes.bool.isRequired,
	setSortChoice: PropTypes.func.isRequired,
	sortUp: PropTypes.bool.isRequired,
	setSortUp: PropTypes.func.isRequired,
};
