import React, { useContext, useEffect, useState } from 'react';
import './_ticketsHeader.scss';
import printerGrey from '../../../../../assets/printerGrey.svg';
import boardGrey from '../../../../../assets/boardGrey.svg';
import boardBlue from '../../../../../assets/boardBlue.svg';
import galleryGrey from '../../../../../assets/galleryGrey.svg';
import galleryBlue from '../../../../../assets/galleryBlue.svg';
import tableBlue from '../../../../../assets/tableBlue.svg';
import tableGrey from '../../../../../assets/tableGrey.svg';
import sortIcon from '../../../../../assets/sortIcon.svg';
import sortIconBlue from '../../../../../assets/sortIconBlue.svg';
import filterIcon from '../../../../../assets/filterIcon.svg';
import filterIconBlue from '../../../../../assets/filterIconBlue.svg';
import TicketFilterItem from './Ticket Filter Item/TicketFilterItem';
import { useTranslation } from 'react-i18next';
import { MISSIONS_STATUS, useTicketProperties } from '../../data';
import upDownBlue from '../../../../../assets/upDownBlue.svg';
import upDownGrey from '../../../../../assets/upDownGrey.svg';
import { TICKET_STATUS } from '../Modal Ticket/Follow Up/FollowUp';
import MultiSnagReport from '../../../../Reports/Multi Snag Report/MultiSnagReport';
import SubMenuPage from '../../../../../components/Sub Menu Page/SubMenuPage';
import ButtonNew from '../../../../../components/Button New/ButtonNew';
import PropTypes from 'prop-types';
import { useProjectContext } from 'src/contexts/projectContext';
import { noLevelId } from 'src/pages/Fiche Projet/Settings Projet/Sub Pages/OnSiteLocations';
import TicketSorting from './Ticket Sorting/TicketSorting';
import { firestore } from 'src/firebase/config';
import { joinPaths } from 'src/firebase/utils';
import { Paths } from 'src/firebase/paths';
import { useProjectLots } from 'src/contexts/projectLotsContext';
import { getMissionPercentage, getMissionStatus, getTicketStatus } from '../../utils';
import AgencyContext from 'src/contexts/agencyContext';

export default function TicketsHeader({
	isSnagging,

	filterSortOpened,
	setFilterSortOpened,

	selectedSort,
	setSelectedSort,

	companyInvolvedSelected,
	setCompanyInvolvedSelected,
	lotsConcernedSelected,
	setLotsConcernedSelected,
	locationOnSiteSelected,
	setLocationOnSiteSelected,
	rolesSelected,
	setRolesSelected,
	statusSelected,
	setStatusSelected,
	visibilitySelected,
	setVisibilitySelected,

	handleCreateTicket,
	tickets,
	activeSubPage,
	setActiveSubPage,
}) {
	const { t } = useTranslation();

	const { properties } = useTicketProperties();
	const [project] = useProjectContext();
	const [lots] = useProjectLots();
	const agencyId = useContext(AgencyContext);

	useEffect(() => {
		const savedFilters = localStorage.getItem(
			isSnagging ? `snagsFilters_${project.id}` : `missionsFilters_${project.id}`
		);
		if (savedFilters) {
			const filters = JSON.parse(savedFilters);
			setCompanyInvolvedSelected(filters.companies || []);
			setLotsConcernedSelected(filters.lots || []);
			setLocationOnSiteSelected(filters.locations || []);
			setRolesSelected(filters.roles || []);
			setStatusSelected(filters.status || []);
			setVisibilitySelected(filters.visibility || []);
		} else {
			setCompanyInvolvedSelected([]);
			setLotsConcernedSelected([]);
			setLocationOnSiteSelected([]);
			setRolesSelected([]);
			setStatusSelected([]);
			setVisibilitySelected([]);
		}

		const savedSort = localStorage.getItem(`${isSnagging ? 'snags' : 'missions'}Sort_${project.id}`);
		if (savedSort) {
			setSelectedSort(
				JSON.parse(savedSort)
					?.map((option) => {
						const sortOption = sortOptions.find((sortOption) => sortOption.key === option.key);
						if (sortOption) {
							return { ...sortOption, ...option };
						}
						return null;
					})
					.filter(Boolean)
			);
		} else {
			setSelectedSort([]);
		}
	}, [project.id, isSnagging]);

	useEffect(() => {
		if (filterSortOpened) {
			const filtersToSave = {
				companies: companyInvolvedSelected,
				lots: lotsConcernedSelected,
				locations: locationOnSiteSelected,
				roles: rolesSelected,
				status: statusSelected,
				visibility: visibilitySelected,
			};
			localStorage.setItem(
				isSnagging ? `snagsFilters_${project.id}` : `missionsFilters_${project.id}`,
				JSON.stringify(filtersToSave)
			);
		}
	}, [
		project.id,
		filterSortOpened,
		companyInvolvedSelected,
		lotsConcernedSelected,
		locationOnSiteSelected,
		rolesSelected,
		statusSelected,
		visibilitySelected,
	]);

	useEffect(() => {
		if (filterSortOpened) {
			const sortToSave =
				selectedSort?.map((option) => ({
					key: option.key,
					order: option.order,
					id: option.id,
				})) ?? [];
			localStorage.setItem(`${isSnagging ? 'snags' : 'missions'}Sort_${project.id}`, JSON.stringify(sortToSave));
		}
	}, [project.id, filterSortOpened, selectedSort]);

	const handleReset = () => {
		setCompanyInvolvedSelected([]);
		setLotsConcernedSelected([]);
		setLocationOnSiteSelected([]);
		setRolesSelected([]);
		setStatusSelected([]);
		setVisibilitySelected([]);
		setSelectedSort([]);
	};

	// ================================================= SUB MENU =================================================

	const subMenuList = [
		{
			name: t('translation.board'),
			key: 'board',
			icon: boardGrey,
			iconBlue: boardBlue,
		},
		{
			name: t('translation.gallery'),
			key: 'gallery',
			icon: galleryGrey,
			iconBlue: galleryBlue,
		},
		{
			name: t('translation.table'),
			key: 'table',
			icon: tableGrey,
			iconBlue: tableBlue,
		},
	];

	const handleClickFilter = () => {
		setFilterSortOpened((prev) => !prev);
	};

	const handleClickSort = () => {
		setFilterSortOpened((prev) => !prev);
	};

	// ================================================= FILTERS =================================================

	const filters = isSnagging
		? [
				{
					key: 'companies',
					name: t('contributors.contributors'),
					options: properties?.reviewers?.options?.map((option) => ({
						...option,
						render: () => <p>{option.label}</p>,
					})),
					selectedOptions: companyInvolvedSelected,
					handleSelect: (optionKey) =>
						setCompanyInvolvedSelected((prev) =>
							prev.includes(optionKey) ? prev.filter((item) => item !== optionKey) : [...prev, optionKey]
						),
					handleClear: () => setCompanyInvolvedSelected([]),
				},
				{
					key: 'lots',
					name: t('translation.lots'),
					options: properties.lots.options.map((option) => ({
						...option,
						render: () => <p>{option.label}</p>,
					})),
					selectedOptions: lotsConcernedSelected,
					handleSelect: (optionKey) =>
						setLotsConcernedSelected((prev) =>
							prev.includes(optionKey) ? prev.filter((item) => item !== optionKey) : [...prev, optionKey]
						),
					handleClear: () => setLotsConcernedSelected([]),
				},
				{
					key: 'locations',
					name: t('translation.localisations'),
					options: Object.values(properties?.locations?.optionsGrouped || {})
						.map((level) => ({
							...{
								...level,
								subOptions: level.locations?.map((location) => ({
									...location,
									render: () => <p>{location.label}</p>,
								})),
							},
							render: () => <p>{level.label}</p>,
						}))
						.sort((a, b) => {
							if (a.key === noLevelId) return -1;
							if (b.key === noLevelId) return 1;
							return a.label?.localeCompare(b.label);
						}),
					selectedOptions: locationOnSiteSelected,
					handleSelect: (optionKey) =>
						setLocationOnSiteSelected((prev) => {
							const level = properties?.locations?.optionsGrouped?.[optionKey];
							if (level) {
								return level.locations?.every((loc) => prev.includes(loc.key))
									? prev.filter((item) => !level.locations.some((loc) => item === loc.key))
									: [...prev, ...(level.locations?.map((loc) => loc.key) ?? [])];
							} else {
								if (prev.includes(optionKey)) {
									return prev.filter((item) => item !== optionKey);
								}
								return [...prev, optionKey];
							}
						}),
					handleClear: () => setLocationOnSiteSelected([]),
				},
				{
					key: 'roles',
					name: t('translation.roles'),
					options: [
						{ key: 'creator', label: t('translation.creator') },
						{ key: 'company', label: t('translation.inCharge') },
						{ key: 'reviewer', label: t('translation.reviewer') },
						{ key: 'user', label: t('translation.viewer') },
					].map((option) => ({
						...option,
						render: () => <p>{option.label}</p>,
					})),
					selectedOptions: rolesSelected,
					handleSelect: (optionKey) =>
						setRolesSelected((prev) =>
							prev.includes(optionKey) ? prev.filter((item) => item !== optionKey) : [...prev, optionKey]
						),
					handleClear: () => setRolesSelected([]),
				},
				{
					key: 'status',
					name: t('translation.status_plural'),
					options: [
						{ key: TICKET_STATUS.new, label: t('translation.newSnag') },
						{ key: TICKET_STATUS.pendingProcess, label: t('translation.waitingForProcessing') },
						{ key: TICKET_STATUS.inProgress, label: t('translation.inProgress') },
						{ key: TICKET_STATUS.pendingApproval, label: t('translation.waitingForApproval') },
						{ key: TICKET_STATUS.refused, label: t('translation.proposalRefused') },
						{ key: TICKET_STATUS.approved, label: t('translation.snagResolved') },
					].map((option) => ({
						...option,
						render: () => <p>{option.label}</p>,
					})),
					selectedOptions: statusSelected,
					handleSelect: (optionKey) =>
						setStatusSelected((prev) =>
							prev.includes(optionKey) ? prev.filter((item) => item !== optionKey) : [...prev, optionKey]
						),
					handleClear: () => setStatusSelected([]),
				},
			]
		: [
				{
					key: 'visibility',
					name: t('translation.visibility'),
					options: [
						{ key: 'collaborative', label: t('translation.collaborative') },
						{ key: 'private', label: t('translation.private') },
					].map((option) => ({
						...option,
						render: () => <p>{option.label}</p>,
					})),
					selectedOptions: visibilitySelected,
					handleSelect: (optionKey) =>
						setVisibilitySelected((prev) =>
							prev.includes(optionKey) ? prev.filter((item) => item !== optionKey) : [...prev, optionKey]
						),
					handleClear: () => setVisibilitySelected([]),
				},
				{
					key: 'status',
					name: t('translation.status_plural'),
					options: [
						{ key: MISSIONS_STATUS.new, label: t('translation.newMission') },
						{ key: MISSIONS_STATUS.ongoing, label: t('translation.ongoing') },
						{ key: MISSIONS_STATUS.finished, label: t('translation.finished') },
					].map((option) => ({
						...option,
						render: () => <p>{option.label}</p>,
					})),
					selectedOptions: statusSelected,
					handleSelect: (optionKey) =>
						setStatusSelected((prev) =>
							prev.includes(optionKey) ? prev.filter((item) => item !== optionKey) : [...prev, optionKey]
						),
					handleClear: () => setStatusSelected([]),
				},
			];

	// ================================================= SORT =================================================

	const sortOptions = isSnagging
		? [
				// All the functions sort the tickets in ascending order
				{
					key: 'name',
					label: t('translation.name'),
					order: 'asc',
					function: (a, b) => a.name.localeCompare(b.name),
				},
				{
					key: 'issueDate',
					label: t('translation.issueDate'),
					order: 'asc',
					function: (a, b) => {
						const dateA = a.createdAt ? new Date(a.createdAt).getTime() : Infinity;
						const dateB = b.createdAt ? new Date(b.createdAt).getTime() : Infinity;
						return dateA - dateB;
					},
				},
				{
					key: 'dueDate',
					label: t('translation.dueDate'),
					order: 'asc',
					function: (a, b) => {
						const dateA = a.properties?.dueAt ? new Date(a.properties.dueAt).getTime() : Infinity;
						const dateB = b.properties?.dueAt ? new Date(b.properties.dueAt).getTime() : Infinity;
						return dateA - dateB;
					},
				},
				{
					key: 'lot',
					label: t('translation.lots'),
					order: 'asc',
					function: (a, b) => a.properties?.lots?.localeCompare(b.properties?.lots),
				},
				{
					key: 'status',
					label: t('translation.status'),
					order: 'asc',
					function: (a, b) => {
						const statusOrder = Object.values(TICKET_STATUS);
						return (
							statusOrder.indexOf(getTicketStatus(a, agencyId)) -
							statusOrder.indexOf(getTicketStatus(b, agencyId))
						);
					},
				},
			]
		: [
				{
					key: 'name',
					label: t('translation.name'),
					order: 'asc',
					function: (a, b) => a.name.localeCompare(b.name),
				},
				{
					key: 'issueDate',
					label: t('translation.issueDate'),
					order: 'asc',
					function: (a, b) => {
						const dateA = a.createdAt ? new Date(a.createdAt).getTime() : Infinity;
						const dateB = b.createdAt ? new Date(b.createdAt).getTime() : Infinity;
						return dateA - dateB;
					},
				},
				{
					key: 'dueDate',
					label: t('translation.dueDate'),
					order: 'asc',
					function: (a, b) => {
						const dateA = a.properties?.dueAt ? new Date(a.properties.dueAt).getTime() : Infinity;
						const dateB = b.properties?.dueAt ? new Date(b.properties.dueAt).getTime() : Infinity;
						return dateA - dateB;
					},
				},
				{
					key: 'status',
					label: t('translation.status'),
					order: 'asc',
					function: (a, b) => {
						const missionStatusOrder = Object.values(MISSIONS_STATUS);

						if (
							getMissionStatus(a) === MISSIONS_STATUS.ongoing &&
							getMissionStatus(b) === MISSIONS_STATUS.ongoing
						)
							return getMissionPercentage(a) - getMissionPercentage(b);

						return (
							missionStatusOrder.indexOf(getMissionStatus(a)) -
							missionStatusOrder.indexOf(getMissionStatus(b))
						);
					},
				},
			];

	// ================================================= RENDER =================================================

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<div className={'ticketsHeader'}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<p className={'ticketsTitle'}>
						{isSnagging ? t('translation.snagging') : t('translation.missions')}
					</p>
				</div>
			</div>

			<div style={{ zIndex: 10 }}>
				<SubMenuPage
					subMenuList={subMenuList}
					activeSubPage={activeSubPage}
					setActiveSubPage={setActiveSubPage}
					large={true}
					ComplementaryComponent={
						<ComplementarySubMenu
							tickets={tickets}
							isSnagging={isSnagging}
							handleCreateTicket={handleCreateTicket}
							handleClickFilter={handleClickFilter}
							handleClickSort={handleClickSort}
							isFiltering={
								companyInvolvedSelected?.length > 0 ||
								lotsConcernedSelected?.length > 0 ||
								locationOnSiteSelected?.length > 0 ||
								rolesSelected?.length > 0 ||
								statusSelected?.length > 0 ||
								visibilitySelected?.length > 0
							}
							isSorting={selectedSort?.length > 0}
						/>
					}
				/>
			</div>

			{filterSortOpened && (
				<div className={'ticketsTagsList'}>
					<div className={'containerTags'}>
						<TicketSorting
							options={sortOptions}
							selectedOptions={selectedSort}
							setSelectedOptions={setSelectedSort}
							handleClear={() => setSelectedSort([])}
						/>
						<div className={'ticketsTagsVerticalBar'} />
						{filters.map((filter, index) => (
							<TicketFilterItem key={index} {...filter} />
						))}
					</div>
					{(companyInvolvedSelected?.length > 0 ||
						lotsConcernedSelected?.length > 0 ||
						locationOnSiteSelected?.length > 0 ||
						rolesSelected?.length > 0 ||
						statusSelected?.length > 0 ||
						visibilitySelected?.length > 0 ||
						selectedSort?.length > 0) && (
						<div className={'resetFilterButton'} onClick={handleReset}>
							{t('translation.reset')}
						</div>
					)}
				</div>
			)}
		</div>
	);
}

function ComplementarySubMenu({
	isSnagging,
	tickets,
	handleCreateTicket,
	handleClickFilter,
	handleClickSort,
	isFiltering,
	isSorting,
}) {
	const [modalPrintTickets, setModalPrintTickets] = useState(false);

	return (
		<div className={'sortFilterCreate'}>
			<div className={'containerIcon'}>
				<img
					src={isFiltering ? filterIconBlue : filterIcon}
					alt=""
					className={'sortIcon'}
					onClick={handleClickFilter}
				/>
			</div>
			<div className={'containerIcon'}>
				<img
					src={isSorting ? sortIconBlue : sortIcon}
					alt=""
					className={'sortIcon'}
					onClick={handleClickSort}
				/>
			</div>

			{isSnagging && tickets?.length > 0 && (
				<div className={'containerIcon'} onClick={() => setModalPrintTickets(true)}>
					<img src={printerGrey} alt="" className={'sortIcon'} />
				</div>
			)}

			<div style={{ marginLeft: 10 }}>
				<ButtonNew handleClick={() => handleCreateTicket(isSnagging ? 'issue' : 'mission')} />
			</div>

			<MultiSnagReport
				isOpen={modalPrintTickets}
				onClose={() => setModalPrintTickets(false)}
				dataTickets={tickets}
			/>
		</div>
	);
}

ComplementarySubMenu.propTypes = {
	isSnagging: PropTypes.bool.isRequired,
	tickets: PropTypes.array.isRequired,
	handleCreateTicket: PropTypes.func.isRequired,
	handleClickFilter: PropTypes.func.isRequired,
	handleClickSort: PropTypes.func.isRequired,
	isFiltering: PropTypes.bool.isRequired,
	isSorting: PropTypes.bool.isRequired,
};
