import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import arrowLeft from '../../../../assets/arrowLeft.svg';
import leftIcon from '../../../../assets/leftIcon.png';
import zoomOutIcon from '../../../../assets/zoomOut.svg';
import zoomOriginal from '../../../../assets/zoomOriginal.svg';
import zoomInIcon from '../../../../assets/zoomIn.svg';
import Modal from 'react-modal/lib/components/Modal';
import annotationIcon from '../../../../assets/annotationIcon.svg';
import AnnotationsPart from './AnnotationsPart';
import screenInfos from '../../../../assets/screenInfos.svg';
import { useTranslation } from 'react-i18next';
import { useProjectContext } from '../../../../contexts/projectContext';
import mixpanel from 'mixpanel-browser';
import { TICKET_STATUS } from '../../Tickets/components/Modal Ticket/Follow Up/FollowUp';
import { auth, firestore } from 'src/firebase/config';
import { generateUniqueFirestoreId, joinPaths, uploadOnStorage } from 'src/firebase/utils';
import { Paths } from 'src/firebase/paths';
import { createdByConstructor } from '../../utils';
import { TICKET_LOG_TYPE } from '../../Tickets/data';
import TypeContext from 'src/contexts/typeContext';
import AgencyContext from 'src/contexts/agencyContext';
import { useClientsContext } from 'src/contexts/clientsContext';
import { useProjectUsersContext } from 'src/contexts/projectUsersContext';
import AuthDataContext from 'src/contexts/authDataContext';
import ModalTicket from 'src/pages/Fiche Projet/Tickets/components/Modal Ticket/ModalTicket';
import { resizeImage } from 'src/useful/image';

export default function ModalPhoto({
	modalPhoto,
	selectedPhoto,
	setSelectedPhoto,
	photos,
	index,
	setIndex,
	zoomIn,
	zoomOut,
	resetTransform,
	transformWrapperRef,
	goBackButtonModalPhoto,
	albumSelected,
	agencyData,
	partnerAgencies,
	type,
	saveAsAlbumCover,
	deletePicture,
	loadingCouvertureAlbum,
	enregistree,
	enregistreeProjectCover,
	loadingProjectCover,
	saveAsProjectCover,
	downloadPhotos,
	setPhotos,
	isModeLecture,
	handleModifyLots,
	handleModifyLocations,
}) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const userType = useContext(TypeContext);
	const authData = useContext(AuthDataContext);
	const agencyId = useContext(AgencyContext);
	const [project] = useProjectContext();
	const [clients] = useClientsContext();
	const [users] = useProjectUsersContext();

	const [bigScreen, setBigScreen] = useState(!!isModeLecture);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [loadingCreateSnag, setLoadingCreateSnag] = useState(false);
	const [modalTicket, setModalTicket] = useState(false);
	const [newTicketData, setNewTicketData] = useState(null);

	const refMenu = useRef(null);

	const isAgencyMatch = (itemAgency) =>
		itemAgency === undefined
			? project?.creator === agencyData?.uid
			: (type === 'clients' && itemAgency === 'clients') || itemAgency === agencyData?.uid;

	useEffect(() => {
		if (selectedPhoto) {
			mixpanel.track('Media View', {
				'Project ID': project.id,
				Page: 'albums',
				'Media type': selectedPhoto.type,
			});
		}
	}, [selectedPhoto]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (refMenu.current && !refMenu.current.contains(event.target)) {
				setIsMenuOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [refMenu]);

	const showMenu = () => {
		setIsMenuOpen(true);
	};

	const createSnag = async () => {
		setLoadingCreateSnag(true);

		try {
			mixpanel.track('Work Created', {
				'Project ID': project.id,
				Page: 'workspace',
				'Work Type': type,
			});

			const id = generateUniqueFirestoreId();
			const fileId = generateUniqueFirestoreId();
			const batch = firestore.batch();

			// Prepare ticket data
			const data = {
				id,
				status: 'ongoing',
				name: t('translation.newSnag'),
				properties: {
					visibility: 'collaborative',
					type: 'issue',
					owner: createdByConstructor(uid, userType, agencyId),
					issueAt: new Date().toISOString(),
					reviewers:
						userType === 'clients' || clients?.map((item) => item.id)?.includes(agencyId)
							? ['clients']
							: [agencyId],
					users: [],
				},
				createdBy: createdByConstructor(uid, userType, agencyId),
				createdAt: new Date().toISOString(),
				modifiedAt: new Date().toISOString(),
				companiesViewed:
					userType === 'clients'
						? ['clients', uid]
						: clients?.map((item) => item.id)?.includes(agencyId)
							? ['clients', agencyId, uid]
							: [agencyId, uid],
				issueStatus: TICKET_STATUS.inProgress,
				missionStatus: 'ongoing',
			};

			// Add ticket to batch
			const ticketRef = firestore.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS)).doc(id);
			batch.set(ticketRef, data);

			// Add description to batch
			const contentRef = firestore
				.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, id, Paths.CONTENT))
				.doc(id);
			batch.set(contentRef, {
				type: 'text',
				title: t('translation.description'),
				text: selectedPhoto?.comments?.length > 0 ? selectedPhoto.comments[0].content : '',
				createdBy: createdByConstructor(uid, userType, agencyId),
				createdAt: new Date().toISOString(),
				modifiedAt: new Date().toISOString(),
			});

			// Add log to batch
			const logRef = firestore
				.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, id, Paths.LOGS))
				.doc();
			batch.set(logRef, {
				type: TICKET_LOG_TYPE.created,
				name: authData.surname + ' ' + authData.name,
				createdAt: new Date().toISOString(),
				createdBy: createdByConstructor(uid, userType, agencyId),
			});

			// Handle file upload separately after batch success
			if (selectedPhoto) {
				const response = await fetch(selectedPhoto.url);
				const blob = await response.blob();
				const file = new File([blob], selectedPhoto.name || `image${fileId}.jpg`, { type: blob.type });
				const processedFile = await resizeImage(file);

				const fileData = {
					id: fileId,
					name: file.name,
					type: file.type.includes('image') ? 'image' : 'file',
					size: processedFile.size,
					mimeType: processedFile.type,
					createdAt: new Date().toISOString(),
					createdBy: createdByConstructor(uid, userType, agencyId),
					modifiedAt: new Date().toISOString(),
				};

				const downloadURL = await uploadOnStorage(
					fileId,
					processedFile,
					{ ...fileData, page: 'tickets' },
					`projects/${project.id}/tickets/${id}/files`
				);

				batch.set(
					firestore
						.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, id, Paths.CONTENT))
						.doc(fileId),
					{
						...fileData,
						url: downloadURL,
					}
				);
			}

			// Commit batch
			await batch.commit();

			setNewTicketData(data);
			setModalTicket(true);
		} catch (error) {
			console.error('Error creating snag:', error);
			alert(t('translation.error_occurred'));
		} finally {
			setLoadingCreateSnag(false);
		}
	};

	const AgencyTag = ({ agency }) => (
		<div style={{ display: 'flex' }}>
			<div
				style={{
					borderRadius: 13,
					marginTop: 3,
					height: 18,
					marginLeft: 0,
					color: '#3f5cf6',
					fontSize: 13,
					fontWeight: '700',
					padding: '3px 8px',
					letterSpacing: 0,
				}}>
				{agency === 'clients' ? 'Client' : partnerAgencies.get(agency)?.diminutifAgency}
			</div>
		</div>
	);

	return (
		<>
			<Modal
				isOpen={modalPhoto}
				className={'modalPhoto'}
				overlayClassName="overlayModalPhoto"
				closeTimeoutMS={300}>
				<div style={{ display: 'flex' }}>
					<div style={{ width: bigScreen ? '100vw' : 'calc(100vw - 400px)' }} className={'transition'}>
						{selectedPhoto &&
							(selectedPhoto.type === 'video' ? (
								<div
									className={'transition'}
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										marginTop: 100,
										position: 'absolute',
										height: '100vh',
										width: bigScreen ? '100vw' : 'calc(100vw - 400px)',
									}}>
									<ReactPlayer
										className="react-player"
										url={selectedPhoto.url}
										controls
										width="100%"
										height={'calc(100vh - 200px)'}
									/>
								</div>
							) : (
								<TransformWrapper ref={transformWrapperRef}>
									<TransformComponent>
										<div
											className={'transition'}
											style={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												height: '100vh',
												marginTop: 0,
												width: bigScreen ? '100vw' : 'calc(100vw - 400px)',
											}}>
											<img
												src={selectedPhoto.url}
												className={'photoOuvert transition'}
												style={{
													cursor: 'pointer',
													maxWidth: bigScreen ? 'calc(100vw - 200px)' : 'calc(100vw - 500px)',
												}}
												alt={''}
											/>
										</div>
									</TransformComponent>
								</TransformWrapper>
							))}
					</div>

					{!isModeLecture && (
						<div
							style={{
								height: '100vh',
								width: bigScreen ? 0 : 400,
								backgroundColor: '#FFF',
								overflow: 'hidden',
							}}
							className={'transition'}>
							<div className={'containerAnnotationIcon'}>
								<div
									className={'transition hover'}
									style={{
										backgroundColor: '#FFF',
										marginLeft: bigScreen ? -570 : -360,
										display: 'flex',
										position: 'absolute',
										zIndex: 2,
										width: 45,
										height: 45,
										borderRadius: 10,
										justifyContent: 'center',
										alignItems: 'center',
									}}>
									<img
										onClick={() => {
											if (bigScreen) {
												setBigScreen(false);
											} else {
												setBigScreen(true);
											}
										}}
										src={screenInfos}
										className={'hover'}
										alt=""
										style={{
											width: 25,
											opacity: 0.7,
											cursor: 'pointer',
											left: 0,
										}}
									/>
								</div>
								{t('common.annotations')}
								<img className={'annotationIcon'} src={annotationIcon} alt="" />
								{selectedPhoto?.comments?.length > 0 && (
									<p className={'numberAnnotations'}>{selectedPhoto.comments.length}</p>
								)}
							</div>

							<AnnotationsPart
								selectedPhoto={selectedPhoto}
								setSelectedPhoto={setSelectedPhoto}
								setPhotos={setPhotos}
							/>
						</div>
					)}
				</div>

				{/* Back Button and Menu Container */}
				<div className={'containerBackMenu'} style={{ backgroundColor: 'rgba(255,255,255,0)' }}>
					<div className={'goBackButton'} onClick={goBackButtonModalPhoto}>
						<img src={arrowLeft} alt="" className={'icon'} />
					</div>

					{!isModeLecture && (
						<div onClick={showMenu} className={'threeDots hover'}>
							<div className={'dot'} style={{ backgroundColor: bigScreen && '#FFF' }} />
							<div className={'dot'} style={{ backgroundColor: bigScreen && '#FFF' }} />
							<div className={'dot'} style={{ backgroundColor: bigScreen && '#FFF' }} />
						</div>
					)}

					<div className={`menuBigPhoto ${isMenuOpen ? 'show' : ''}`} ref={refMenu}>
						<div
							onClick={() => {
								downloadPhotos([selectedPhoto]);
							}}
							className={'buttonMenuBigPhoto'}>
							{t('photos.download_image')}
						</div>

						{/* Define as Cover Button */}
						<div
							style={{
								color: enregistreeProjectCover ? '#569F59' : null,
							}}
							onClick={saveAsProjectCover}
							className={'buttonMenuBigPhoto'}>
							{loadingProjectCover
								? t('photos.addition_in_progression')
								: enregistreeProjectCover
									? t('photos.saved')
									: t('photos.define_as_cover')}
						</div>

						{/* Add to Cover Button */}
						{albumSelected?.type !== 'visit' && (
							<div
								style={{
									color: enregistree ? '#569F59' : null,
									opacity: isAgencyMatch(albumSelected?.createdBy?.agencyId) ? 1 : 0.4,
								}}
								onClick={saveAsAlbumCover}
								className={'buttonMenuBigPhoto'}>
								{loadingCouvertureAlbum
									? t('photos.addition_in_progression')
									: enregistree
										? t('photos.saved')
										: t('photos.add_to_cover')}
								{albumSelected && !isAgencyMatch(albumSelected?.createdBy?.agencyId) && (
									<AgencyTag agency={albumSelected?.createdBy?.agencyId} />
								)}
							</div>
						)}

						<div onClick={createSnag} className={'buttonMenuBigPhoto'}>
							{loadingCreateSnag
								? t('translation.snag_creation_in_progress')
								: t('translation.create_snag')}
						</div>

						{/* Delete Picture Button */}
						{albumSelected && (
							<div
								onClick={deletePicture}
								className={'buttonMenuBigPhoto'}
								style={{
									color: '#d94040',
									opacity: isAgencyMatch(selectedPhoto?.createdBy?.agencyId) ? 1 : 0.4,
								}}>
								{t('photos.delete_pic')}
								{selectedPhoto && !isAgencyMatch(selectedPhoto?.createdBy?.agencyId) && (
									<AgencyTag agency={selectedPhoto?.createdBy?.agencyId} />
								)}
							</div>
						)}
					</div>
				</div>

				{/* Zoom and Navigation Controls */}

				<div>
					{selectedPhoto && selectedPhoto.type === 'video' && (
						<div style={{ height: 'calc(100vh - 90px)' }} />
					)}

					<div
						className={'containerZoomNav transition'}
						style={{
							position: selectedPhoto && selectedPhoto.type === 'video' && 'relative',
							width: bigScreen ? '100vw' : 'calc(100vw - 400px)',
						}}>
						<div
							className={'previousNext'}
							style={{ opacity: index === 0 ? 0.5 : 1 }}
							onClick={() => {
								const img = photos?.[index - 1];
								if (img) {
									setSelectedPhoto(img);
									setIndex(index - 1);
								}
							}}>
							<img src={leftIcon} alt="" className={'leftIcon'} />
						</div>

						{selectedPhoto && selectedPhoto.type !== 'video' && (
							<div className={'zoomInOut'}>
								<div className={'buttonZoom'} onClick={zoomOut}>
									<img src={zoomOutIcon} alt="" className={'zoomOutIcon'} />
								</div>
								<div className={'buttonZoom'} onClick={resetTransform}>
									<img src={zoomOriginal} alt="" className={'zoomOutIcon'} />
								</div>
								<div className={'buttonZoom'} onClick={zoomIn}>
									<img src={zoomInIcon} alt="" className={'zoomOutIcon'} />
								</div>
							</div>
						)}

						<div
							className={'previousNext'}
							onClick={() => {
								const img = photos?.[index + 1];
								if (img) {
									setSelectedPhoto(img);
									setIndex(index + 1);
								}
							}}>
							<img src={leftIcon} alt="" className={'rightIcon'} />
						</div>
					</div>
				</div>
			</Modal>

			<ModalTicket
				isOpen={modalTicket}
				data={newTicketData}
				onClose={() => {
					setModalTicket(false);
					setTimeout(() => {
						setNewTicketData(null);
					}, 100);
				}}
				isSnagging={true}
				darkerOverlay={true}
				handleModifyLots={handleModifyLots}
				handleModifyLocations={handleModifyLocations}
			/>
		</>
	);
}
