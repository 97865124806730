import React, { useMemo } from 'react';
import './_subPageSettingsProject.scss';
import mappin from '../../../../assets/mappin.svg';
import Table, { NewGroup } from '../../../../components/Table/Table';
import { useProjectContext } from 'src/contexts/projectContext';
import { generateUniqueFirestoreId } from 'src/firebase/utils';
import { firestore } from 'src/firebase/config';
import { useProjectLocations } from 'src/contexts/projectLocationsContext';
import { useTranslation } from 'react-i18next';

export const noLevelId = 'no-level';

export default function OnSiteLocations() {
	const { t } = useTranslation();

	const propertiesLocations = [
		{
			type: 'text',
			label: t('translation.location'),
			icon: mappin,
		},
		{
			type: 'select',
			label: t('translation.level'),
			icon: mappin,
		},
		/*{
			type: 'text',
			label: 'Point on map',
			icon: mappin,
		},*/
	];

	const [project] = useProjectContext();
	const [locations, setLocations] = useProjectLocations();

	const locationsGrouped = useMemo(() => {
		return (
			locations
				?.filter((location) => location.type !== 'level')
				?.reduce((groups, location) => {
					const level = locations.find((l) => l.id === location.levelId);
					const key = level ? (level.id ?? noLevelId) : noLevelId;

					if (!groups[key]) {
						groups[key] = {
							id: key,
							name:
								key === noLevelId
									? t('translation.noLevel')
									: locations.find((l) => l.id === key)?.name || '',
							locations: [],
						};
					}

					groups[key].locations = [...groups[key].locations, location];

					return groups;
				}, {}) || {}
		);
	}, [locations]);

	const handleNewElem = async (levelId) => {
		setLocations((prevState) => [
			...prevState,
			{ id: generateUniqueFirestoreId(), levelId: levelId, name: '', createdAt: new Date().toISOString() },
		]);
	};

	const handleNameChange = async (locationId, value) => {
		await firestore
			.collection('projects')
			.doc(project.id)
			.collection('locations')
			.doc(locationId)
			.set(
				{
					name: value?.trim() || '',
					levelId: locations.find((l) => l.id === locationId)?.levelId || '',
					createdAt: locations.find((l) => l.id === locationId)?.createdAt || new Date().toISOString(),
				},
				{ merge: true }
			);
	};

	const handleLevelChange = async (locationId, value) => {
		await firestore
			.collection('projects')
			.doc(project.id)
			.collection('locations')
			.doc(locationId)
			.set(
				{
					levelId: value || '',
					createdAt: locations.find((l) => l.id === locationId)?.createdAt || new Date().toISOString(),
				},
				{ merge: true }
			);
	};

	const handleNewLevel = async (name) => {
		const levelId = generateUniqueFirestoreId();
		setLocations((prevState) => [
			...prevState,
			{ id: levelId, type: 'level', name: name.trim(), createdAt: new Date().toISOString() },
		]);
		await firestore
			.collection('projects')
			.doc(project.id)
			.collection('locations')
			.doc(levelId)
			.set({ name: name.trim(), type: 'level', createdAt: new Date().toISOString() }, { merge: true });
	};

	const handleDeleteLocations = async (ids) => {
		const batch = firestore.batch();

		ids.forEach((id) => {
			batch.update(firestore.collection('projects').doc(project.id).collection('locations').doc(id), {
				isDeleted: true,
			});
		});

		await batch.commit();
	};

	const handleNewLevelInDropDown = async (locationId, levelName) => {
		const levelId = generateUniqueFirestoreId();
		setLocations((prevState) =>
			[
				...prevState,
				{ id: levelId, type: 'level', name: levelName.trim(), createdAt: new Date().toISOString() },
			].sort((a, b) => a.name?.localeCompare(b.name))
		);

		await handleLevelChange(locationId, levelId);

		await firestore
			.collection('projects')
			.doc(project.id)
			.collection('locations')
			.doc(levelId)
			.set({ name: levelName.trim(), type: 'level', createdAt: new Date().toISOString() }, { merge: true });
	};

	const handleRenameLevel = async (levelId, newName) => {
		await firestore
			.collection('projects')
			.doc(project.id)
			.collection('locations')
			.doc(levelId)
			.set({ name: newName.trim() }, { merge: true });
	};

	const handleDeleteLevel = async (levelId) => {
		await firestore
			.collection('projects')
			.doc(project.id)
			.collection('locations')
			.doc(levelId)
			.set({ isDeleted: true }, { merge: true });
	};

	return (
		<div className={'subPageSettingsProject'}>
			<p className={'titleSubPage'}>{t('translation.locaOnSite')}</p>
			<p className={'subtitleSubPage'}>{t('translation.subtitleLocaOnSite')}</p>

			{locations
				?.filter((item) => item.type === 'level')
				?.map((level) => (
					<Table
						key={level.id}
						columns={propertiesLocations}
						rows={
							locationsGrouped[level.id]?.locations?.map((location) => ({
								id: location.id,
								content: [
									{
										value: location.name,
										onValidate: (value) => handleNameChange(location.id, value),
									},
									{
										value: [level.id],
										onValidate: (value) => handleLevelChange(location.id, value),
										options: locations
											.filter((l) => l.type === 'level')
											.map((l) => ({ key: l.id, label: l.name })),
										onCreate: (value) => handleNewLevelInDropDown(location.id, value),
										optionType: 'levels',
									},
								],
							})) ?? []
						}
						isGroup={true}
						titleGroup={level.name}
						handleNewElem={() => handleNewElem(level.id)}
						handleDeleteRows={handleDeleteLocations}
						handleRenameGroup={(newName) => handleRenameLevel(level.id, newName)}
						handleDeleteGroup={() => handleDeleteLevel(level.id)}
					/>
				))}

			<Table
				key={noLevelId}
				columns={propertiesLocations}
				rows={
					locationsGrouped[noLevelId]?.locations?.map((location) => ({
						id: location.id,
						content: [
							{ value: location.name, onValidate: (value) => handleNameChange(location.id, value) },
							{
								value: [noLevelId],
								onValidate: (value) => handleLevelChange(location.id, value),
								options: locations
									.filter((l) => l.type === 'level')
									.map((l) => ({ key: l.id, label: l.name })),
								onCreate: (value) => handleNewLevelInDropDown(location.id, value),
								optionType: 'levels',
							},
						],
					})) ?? []
				}
				isGroup={true}
				titleGroup={t('translation.noLevel')}
				handleNewElem={() => handleNewElem(noLevelId)}
				handleDeleteRows={handleDeleteLocations}
			/>

			<div style={{ display: 'flex' }}>
				<NewGroup title={t('translation.addALevel')} createGroup={handleNewLevel} />
			</div>
		</div>
	);
}
