import React, { useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { MISSIONS_STATUS, useTicketProperties } from '../../data';
import { auth, firestore } from '../../../../../firebase/config';
import TypeContext from '../../../../../contexts/typeContext';
import AgencyContext from '../../../../../contexts/agencyContext';
import { useProjectContext } from '../../../../../contexts/projectContext';
import { useClientsContext } from '../../../../../contexts/clientsContext';
import { getMissionPercentage, getTicketStatus } from '../../utils';
import { joinPaths } from '../../../../../firebase/utils';
import { Paths } from '../../../../../firebase/paths';
import { TICKET_STATUS } from '../Modal Ticket/Follow Up/FollowUp';
import { formatDateNumericallyWithTodayOrYesterdayOrTomorrow } from '../../../../../useful/date';
import mappin from '../../../../../assets/mappin.svg';
import CompanyOrPersonItem from '../Little Components/Company Or Person Item/CompanyOrPersonItem';
import blur from '../../../../../assets/blur.jpg';
import PropTypes from 'prop-types';
import './_ticketCard.scss';

TicketCard.propTypes = {
	ticket: PropTypes.object.isRequired,
	isSnagging: PropTypes.bool.isRequired,
	index: PropTypes.number.isRequired,
	gallery: PropTypes.bool,
	board: PropTypes.bool,
	scrollLeft: PropTypes.number,
};

export default function TicketCard({ ticket, isSnagging, gallery, index, board, scrollLeft }) {
	const { t, i18n } = useTranslation();

	const { properties } = useTicketProperties();

	const uid = auth.currentUser?.uid;
	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);
	const [project] = useProjectContext();
	const [clients] = useClientsContext();

	const [images, setImages] = useState([]);
	const [status, setStatus] = useState(null);

	// ================================================ STATUS ================================================

	useEffect(() => {
		const isClient = type === 'clients' || clients?.map((client) => client.id)?.includes(agencyId);
		if (ticket?.properties?.type === 'issue' && (isClient || agencyId)) {
			setStatus(getTicketStatus(ticket, isClient ? 'clients' : agencyId));
		}
	}, [agencyId, ticket]);

	// ================================================ FILES ================================================

	useEffect(() => {
		if (project?.id && ticket?.id && gallery) {
			const unsubscribe = firestore
				.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, ticket.id, Paths.CONTENT))
				.where('type', 'in', ['image', 'file'])
				.limit(1)
				.onSnapshot((querySnapshot) => {
					const images = [];
					querySnapshot.docs.forEach((doc) => {
						if (doc.data().type === 'image') images.push({ ...doc.data(), id: doc.id });
					});
					setImages(images);
				});

			return () => unsubscribe();
		}
	}, [project?.id, ticket?.id, gallery]);

	if (board) {
		return (
			<div className={'ticketCardBoard'}>
				<p className={'ticketName'} style={{ marginBottom: -10 }}>
					<span style={{ color: '#000', marginRight: 5 }}>#{index}</span>
				</p>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'flex-start',
					}}>
					<p className={'ticketName'}>
						{ticket.name
							? ticket.name
							: isSnagging
								? t('translation.newSnag')
								: t('translation.newMission')}
					</p>
					{!ticket?.companiesViewed?.includes(uid) && !isSnagging && (
						<div className={'pastilleOrange'}>1</div>
					)}
				</div>

				{ticket.properties.locations?.length > 0 && (
					<div className={'locationOnSite'}>
						<img src={mappin} alt="" />
						<div style={{ display: 'flex', gap: 5, flexWrap: 'wrap', marginLeft: 5 }}>
							{ticket.properties.locations.map((location, index) => (
								<div style={{ display: 'flex', alignItems: 'center', gap: 5 }} key={index}>
									<p style={{ margin: 0 }}>
										{properties.locations.options?.find((option) => option.key === location)
											?.type !== 'level' &&
										properties.locations.options?.find(
											(level) =>
												level.key ===
												properties.locations.options?.find((option) => option.key === location)
													?.levelId
										)
											? properties.locations.options?.find(
													(level) =>
														level.key ===
														properties.locations.options?.find(
															(option) => option.key === location
														)?.levelId
												)?.label + ' • '
											: ''}
										{properties.locations.options.find((option) => option.key === location)?.label}
									</p>
									{index < ticket.properties.locations.length - 1 && <p style={{ margin: 0 }}>•</p>}
								</div>
							))}
						</div>
					</div>
				)}

				{ticket.properties?.companies?.map((company, index) => (
					<CompanyOrPersonItem
						key={index}
						agencyId={company}
						unClickable={true}
						maxWidth={300}
						company={true}
					/>
				))}

				{(ticket.properties.dueAt || ticket.properties.expectingDuration) && (
					<div className={'containerTiming'}>
						{ticket.properties.dueAt && (
							<div
								className={
									new Date(ticket.properties.dueAt) < new Date() &&
									ticket.issueStatus !== TICKET_STATUS.approved &&
									ticket.missionStatus !== MISSIONS_STATUS.finished
										? 'timingItem timingItemOverdue'
										: 'timingItem'
								}>
								{new Date(ticket.properties.dueAt) < new Date() &&
									ticket.issueStatus !== TICKET_STATUS.approved &&
									ticket.missionStatus !== MISSIONS_STATUS.finished &&
									t('translation.overdue')}{' '}
								{formatDateNumericallyWithTodayOrYesterdayOrTomorrow(
									ticket.properties.dueAt,
									i18n.language,
									t
								)}
							</div>
						)}
						{ticket.properties.expectingDuration && (
							<div className={'timingItem'}>{ticket.properties.expectingDuration}</div>
						)}
					</div>
				)}
			</div>
		);
	} else if (gallery) {
		return (
			<div className={'ticketCardGallery'}>
				{images.length > 0 ? (
					<img src={images[0].url} alt="" className={'ticketImage'} />
				) : (
					<div className={'ticketImage'} style={{ marginBottom: 11 }} />
				)}
				<p className={'ticketName'}>
					<span style={{ opacity: 0.5, marginRight: 10 }}>#{index}</span>{' '}
					{ticket.name ? ticket.name : isSnagging ? t('translation.newSnag') : t('translation.newMission')}
				</p>
			</div>
		);
	} else {
		return (
			<div className={'ticketCardTable'}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div className={'ticketIndex'} style={{ borderRightColor: scrollLeft > 20 && '#eeeeee' }}>
						#{index}
					</div>
					<p className={'ticketName'}>
						{ticket.name
							? ticket.name
							: isSnagging
								? t('translation.newSnag')
								: t('translation.newMission')}{' '}
					</p>
				</div>

				<div style={{ width: 200 }}>
					{(ticket.properties.dueAt || ticket.properties.expectingDuration) && (
						<div className={'containerTiming'}>
							{ticket.properties.dueAt && (
								<div
									className={
										new Date(ticket.properties.dueAt) < new Date() &&
										ticket.issueStatus !== TICKET_STATUS.approved &&
										ticket.missionStatus !== MISSIONS_STATUS.finished
											? 'timingItem timingItemOverdue'
											: 'timingItem'
									}>
									{new Date(ticket.properties.dueAt) < new Date() &&
										ticket.issueStatus !== TICKET_STATUS.approved &&
										ticket.missionStatus !== MISSIONS_STATUS.finished &&
										t('translation.overdue')}{' '}
									{formatDateNumericallyWithTodayOrYesterdayOrTomorrow(
										ticket.properties.dueAt,
										i18n.language,
										t
									)}
								</div>
							)}
						</div>
					)}
				</div>

				<div style={{ width: 300, display: 'flex', justifyContent: 'flex-end' }}>
					{isSnagging ? (
						status === TICKET_STATUS.new ? (
							<div
								className={'tagStatus'}
								style={{ color: '#ff6a00', backgroundColor: 'rgba(255, 153, 0, 0.1)' }}>
								{t('translation.newSnag')}
							</div>
						) : status === TICKET_STATUS.pendingProcess ? (
							<div
								className={'tagStatus'}
								style={{ color: '#1C57DD', backgroundColor: 'rgba(28,87,221,0.1)' }}>
								{t('translation.waitingForProcessing')}
							</div>
						) : status === TICKET_STATUS.inProgress ? (
							ticket?.properties?.companies?.includes(agencyId) ? (
								<div
									className={'tagStatus'}
									style={{
										color: '#ff6a00',
										backgroundColor: 'rgba(255, 153, 0, 0.1)',
									}}>
									{t('translation.waitingForYourResolution')}
								</div>
							) : (
								<div
									className={'tagStatus'}
									style={{
										color: '#1C57DD',
										backgroundColor: 'rgba(28,87,221,0.1)',
									}}>
									{t('translation.inProgress')}
								</div>
							)
						) : status === TICKET_STATUS.pendingApproval ? (
							<div
								className={'tagStatus'}
								style={{
									color: ticket?.properties?.companies?.includes(agencyId) ? '#1C57DD' : '#ff6a00',
									backgroundColor: ticket?.properties?.companies?.includes(agencyId)
										? 'rgba(28,87,221,0.1)'
										: 'rgba(255, 153, 0, 0.1)',
								}}>
								{t('translation.waitingForApproval')}
							</div>
						) : status === TICKET_STATUS.refused ? (
							ticket?.companiesViewedRefused?.includes(agencyId) ? (
								ticket?.properties?.companies?.includes(agencyId) ? (
									<div
										className={'tagStatus'}
										style={{ color: '#ff6a00', backgroundColor: 'rgba(255,153,0,0.1)' }}>
										{t('translation.waitingForYourResolution')}
									</div>
								) : (
									<div
										className={'tagStatus'}
										style={{ color: '#1C57DD', backgroundColor: 'rgba(28,87,221,0.1)' }}>
										{t('translation.inProgress')}
									</div>
								)
							) : (
								<div
									className={'tagStatus'}
									style={{ color: '#ff0000', backgroundColor: 'rgba(255,0,0,0.1)' }}>
									{t('translation.proposalRefused')}
								</div>
							)
						) : status === TICKET_STATUS.approved ? (
							<div
								className={'tagStatus'}
								style={{ color: '#3CA504', backgroundColor: 'rgba(60,165,4,0.1)' }}>
								{t('translation.snagResolved')}
							</div>
						) : null
					) : ticket.missionStatus === MISSIONS_STATUS.finished ? (
						<div
							className={'tagStatus'}
							style={{ color: '#3CA504', backgroundColor: 'rgba(60,165,4,0.1)' }}>
							{t('translation.missionFinished')}
						</div>
					) : (
						<div className={'progressBar'}>
							<img
								className={'progressBar'}
								src={blur}
								style={{
									width: `${getMissionPercentage(ticket)}%`,
								}}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
}
