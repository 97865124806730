import React, { useContext, useState } from 'react';
import './_settingsProjets.scss';
import Informations from './Sub Pages/Informations';
import { useTranslation } from 'react-i18next';
import ProjectSynthesisReport from '../../Reports/Project Synthesis Report/ProjectSynthesisReport';
import { Paths } from 'src/firebase/paths';
import { joinPaths } from 'src/firebase/utils';
import { auth, firestore } from 'src/firebase/config';
import printer from '../../../assets/printer.svg';
import AuthDataContext from 'src/contexts/authDataContext';
import AgencyContext from 'src/contexts/agencyContext';
import { PR_ID } from 'src/useful/constants';
import SubMenuPage from '../../../components/Sub Menu Page/SubMenuPage';
import General from './Sub Pages/General';
import Lots from './Sub Pages/Lots';
import OnSiteLocations from './Sub Pages/OnSiteLocations';
import Functionalities from './Sub Pages/Functionalities';
import { useProjectContext } from 'src/contexts/projectContext';
import PropTypes from 'prop-types';

SettingsProjet.propTypes = {
	defaultSubPage: PropTypes.string,
};

export default function SettingsProjet({ defaultSubPage }) {
	const { t } = useTranslation();

	const subMenuSettings = [
		{
			name: t('translation.general'),
			key: 'general',
			component: <General />,
		},
		{
			name: t('translation.informations'),
			key: 'informations',
			component: <Informations />,
		},
		{
			name: t('translation.lots'),
			key: 'lots',
			component: <Lots />,
		},
		{
			name: t('translation.locaOnSite'),
			key: 'localisations',
			component: <OnSiteLocations />,
		},
		{
			name: t('translation.functionalities'),
			key: 'functionalities',
			component: <Functionalities />,
		},
	];

	const uid = auth.currentUser?.uid;
	const agencyId = useContext(AgencyContext);
	const authData = useContext(AuthDataContext);
	const isAdmin = authData?.subtype === 'administrator';
	const [project] = useProjectContext();

	const [activeSubPage, setActiveSubPage] = useState(
		defaultSubPage && subMenuSettings.some((item) => item.key === defaultSubPage)
			? defaultSubPage
			: subMenuSettings[0].key
	);

	const [modalProjectSynthesisReport, setModalProjectSynthesisReport] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [missions, setMissions] = useState([]);
	const [collaborators, setCollaborators] = useState([]);

	const openReport = async () => {
		setIsLoading(true);

		let missions = (
			await firestore
				.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS))
				.where('properties.type', '==', 'mission')
				.where('properties.visibility', '!=', 'private')
				.get()
		).docs.map((doc) => ({ ...doc.data(), id: doc.id }));
		missions = missions
			?.filter((mission) => mission.properties?.owner?.userId === uid || mission.properties?.users?.includes(uid))
			.sort((a, b) => {
				if (!a.properties?.dueAt && !b.properties?.dueAt) return 0;
				if (!a.properties?.dueAt) return 1;
				if (!b.properties?.dueAt) return -1;
				return new Date(a.properties.dueAt) - new Date(b.properties.dueAt);
			});

		let collaborators = (
			await firestore
				.collection(joinPaths(Paths.PROJECTS, project.id, Paths.ACCOUNTS))
				.where('type', '==', 'collaborators')
				.where('agency', '==', agencyId)
				.get()
		).docs.map((doc) => ({ ...doc.data(), id: doc.id }));

		const collaboratorsData = [];
		await Promise.all(
			collaborators.map(
				async (collaborator) =>
					new Promise((resolve) => {
						firestore
							.doc(joinPaths(Paths.AGENCIES, agencyId, Paths.COLLABORATORS, collaborator.id))
							.get()
							.then((doc) => {
								collaboratorsData.push({ ...collaborator, ...doc.data() });
								resolve();
							});
					})
			)
		);

		setMissions(missions);
		setCollaborators(collaboratorsData.filter((collaborator) => collaborator.subtype !== 'administrator'));

		setModalProjectSynthesisReport(true);
		setIsLoading(false);
	};

	return (
		<div className={'settingsProjet'}>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: 30 }}>
				<p className={'titlePage'}>{t('common.settings')}</p>

				{isAdmin && agencyId === PR_ID && (
					<div
						className={'hover printButton'}
						style={{ marginRight: 20, marginTop: 50, marginBottom: -30 }}
						onClick={openReport}>
						<img src={printer} alt="" className={' printer'} />
						{t('translation.printSynthesis')}
					</div>
				)}
			</div>

			<SubMenuPage
				subMenuList={subMenuSettings}
				activeSubPage={activeSubPage}
				setActiveSubPage={setActiveSubPage}
				large={true}
			/>

			{subMenuSettings.find((item) => item.key === activeSubPage)?.component}

			<ProjectSynthesisReport
				isOpen={modalProjectSynthesisReport}
				onClose={() => setModalProjectSynthesisReport(false)}
				projects={[{ ...project, missions, collaborators }]}
			/>

			{isLoading && (
				<div className="deletion-overlay">
					<div className="deletion-spinner"></div>
					<p>{t('translation.loading_report')}</p>
				</div>
			)}
		</div>
	);
}
