import React, { useRef, useContext, useMemo, useEffect, useState } from 'react';
import './_multiSnagReport.scss';
import { useReactToPrint } from 'react-to-print';
import printer from '../../../assets/printer.svg';
import crossIcon from '../../../assets/crossIcon.png';
import Building from '../../../assets/Building.svg';
import SnagPage from './Snag Page/SnagPage';
import Modal from 'react-modal/lib/components/Modal';
import { useProjectContext } from '../../../contexts/projectContext';
import AgencyDataContext from '../../../contexts/agencyDataContext';
import { useTranslation } from 'react-i18next';
import { usePartnersContext } from 'src/contexts/partnersContext';
import { TICKET_STATUS } from '../../Fiche Projet/Tickets/components/Modal Ticket/Follow Up/FollowUp';

export default function MultiSnagReport({ dataTickets, isOpen, onClose }) {
	const { t, i18n } = useTranslation();

	const printRef = useRef();
	const [project] = useProjectContext();
	const agencyData = useContext(AgencyDataContext);
	const [partners] = usePartnersContext();

	const handlePrint = useReactToPrint({
		contentRef: printRef,
		documentTitle: `${project.name}-${t('translation.snagListReport')}-${new Date().toLocaleDateString(
			i18n.language,
			{
				month: 'numeric',
				day: 'numeric',
				year: 'numeric',
			}
		)}`,
	});

	const handlePrintClick = () => {
		handlePrint();
	};

	const data = useMemo(() => {
		return {
			in_progress: dataTickets.filter(
				(item) =>
					item.issueStatus !== TICKET_STATUS.approved &&
					(!item.properties.dueAt || item.properties.dueAt > new Date().toISOString())
			).length,
			overdue: dataTickets.filter(
				(item) =>
					item.issueStatus !== TICKET_STATUS.approved &&
					item.properties.dueAt &&
					item.properties.dueAt <= new Date().toISOString()
			).length,
			resolved: dataTickets.filter((item) => item.issueStatus === TICKET_STATUS.approved).length,
		};
	}, [dataTickets]);

	const companies = useMemo(() => {
		const allCompanyIds = [
			...new Set(
				dataTickets.reduce((acc, ticket) => {
					const companyIds = ticket.properties.companies || [];
					return [...acc, ...companyIds];
				}, [])
			),
		];

		return allCompanyIds.map((companyId) => {
			const companyTickets = dataTickets.filter((ticket) => {
				const companies = ticket.properties.companies || [];
				return companies.includes(companyId);
			});

			return {
				id: companyId,
				in_progress: companyTickets.filter(
					(ticket) =>
						ticket.issueStatus !== 'approved' &&
						(!ticket.properties.dueAt || ticket.properties.dueAt > new Date().toISOString())
				).length,
				overdue: companyTickets.filter(
					(ticket) =>
						ticket.issueStatus !== 'approved' &&
						ticket.properties.dueAt &&
						ticket.properties.dueAt <= new Date().toISOString()
				).length,
				resolved: companyTickets.filter((ticket) => ticket.issueStatus === 'approved').length,
			};
		});
	}, [dataTickets]);

	const calculateWidth = (value) => {
		return value ? `${(790 * value) / (data.in_progress + data.overdue + data.resolved)}px` : '0px';
	};

	return (
		<Modal isOpen={isOpen} closeTimeoutMS={300} className={'page'}>
			<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 25 }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', width: '210mm', alignItems: 'center' }}>
					<div style={{ width: 25 }} />
					<span style={{ height: 50 }} onClick={handlePrintClick} className={'buttonPrint hover'}>
						<img src={printer} alt="" /> {t('translation.printReport')}
					</span>
					<img src={crossIcon} alt="" className={'crossIcon hover'} onClick={onClose} />
				</div>

				<div className={'largeBar'} />
			</div>

			<div className={'containerPage'} ref={printRef}>
				<div className={'pdfPage'}>
					<img src={project.imgUrl} alt="" className={'projectPicture'} />

					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							marginTop: 15,
							alignItems: 'center',
						}}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginTop: 20,
								marginBottom: 20,
							}}>
							<span className={'titleReport'}>{t('translation.snagListReport')}</span>

							<span className={'reportDate'}>
								{' '}
								{new Date().toLocaleDateString(i18n.language, {
									weekday: 'short',
									month: 'short',
									day: 'numeric',
									year: 'numeric',
								})}
							</span>
							<span className={'reportDate'}>
								{t('translation.items')} {dataTickets?.length}
							</span>
						</div>

						<div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
							<img src={agencyData?.logoUrl} alt="" className={'agencyLogo'} />
						</div>
					</div>

					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							height: 680,
						}}>
						<div className={'containerStakeholders'}>
							<div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
								<ItemCompany companyName={t('translation.companyName')} header={true} />
								<div style={{ display: 'flex', height: 1, backgroundColor: '#eeeeee' }} />
								{companies.map((company) => (
									<ItemCompany
										key={company?.id}
										companyName={
											[agencyData, ...partners].find((partner) => partner?.id === company?.id)
												?.name
										}
										numberInProgress={company.in_progress}
										numberOverdue={company.overdue}
										numberResolved={company.resolved}
									/>
								))}
							</div>
						</div>

						<div className="chartNumbers">
							{data.in_progress > 0 && (
								<div
									className="containerChartNumber"
									style={{
										width: calculateWidth(data.in_progress),
									}}>
									<p style={{ color: '#1C57DD' }}>{data.in_progress}</p>
									<div
										className="chartLargeBar"
										style={{
											backgroundColor: '#1C57DD',
										}}
									/>
								</div>
							)}
							{data.overdue > 0 && (
								<div
									className="containerChartNumber"
									style={{
										width: calculateWidth(data.overdue),
									}}>
									<p style={{ color: '#ff0000' }}>{data.overdue}</p>
									<div
										className="chartLargeBar"
										style={{
											backgroundColor: '#ff0000',
										}}
									/>
								</div>
							)}
							{data.resolved > 0 && (
								<div
									className="containerChartNumber"
									style={{
										width: calculateWidth(data.resolved),
									}}>
									<p style={{ color: '#3CA504' }}>{data.resolved}</p>
									<div
										className="chartLargeBar"
										style={{
											backgroundColor: '#3CA504',
										}}
									/>
								</div>
							)}
						</div>
					</div>
				</div>

				{dataTickets?.map((ticket) => (
					<div className={'pdfPage'} key={ticket.id}>
						<SnagPage ticket={ticket} index={ticket.index} ticketsLength={dataTickets?.length} />
					</div>
				))}
			</div>
		</Modal>
	);
}

function ItemCompany({ companyName, numberInProgress, numberOverdue, numberResolved, header }) {
	const { t } = useTranslation();

	return (
		<div className={'itemCompany'} style={{ fontWeight: header && '700' }}>
			<div style={{ width: '40%', display: 'flex', alignItems: 'center' }}>
				{!header && (
					<div style={{ display: 'flex' }}>
						<div className={'containerBuildingIcon'}>
							<img src={Building} alt="" className={'buildingIcon'} />
						</div>
					</div>
				)}{' '}
				<span className={'companyName'}>{companyName}</span>
			</div>
			<div
				style={{
					color: '#1C57DD',
					width: '20%',
					justifyContent: 'center',
					display: 'flex',
				}}>
				{header ? t('translation.ongoing') : numberInProgress}
			</div>
			<div style={{ color: '#ff0000', width: '20%', justifyContent: 'center', display: 'flex' }}>
				{header ? t('translation.overdue') : numberOverdue}
			</div>
			<div style={{ color: '#3CA504', width: '20%', justifyContent: 'center', display: 'flex' }}>
				{header ? t('translation.resolved') : numberResolved}
			</div>
		</div>
	);
}
