import React, { useContext, useEffect, useState } from 'react';
import './_snagPage.scss';
import mappin from '../../../../assets/mappin.svg';
import hammer from '../../../../assets/hammer.svg';
import CompanyOrPersonItem from '../../../Fiche Projet/Tickets/components/Little Components/Company Or Person Item/CompanyOrPersonItem';
import { useTicketProperties } from '../../../Fiche Projet/Tickets/data';
import { firestore } from '../../../../firebase/config';
import { joinPaths } from '../../../../firebase/utils';
import { Paths } from '../../../../firebase/paths';
import { useProjectContext } from '../../../../contexts/projectContext';
import { formatDateString } from '../../../../useful/date';
import { useTranslation } from 'react-i18next';
import { getTicketStatus } from '../../../Fiche Projet/Tickets/utils';
import AgencyContext from '../../../../contexts/agencyContext';
import TypeContext from '../../../../contexts/typeContext';
import { useClientsContext } from '../../../../contexts/clientsContext';
import { TICKET_STATUS } from '../../../Fiche Projet/Tickets/components/Modal Ticket/Follow Up/FollowUp';
import SnagDescription from './Snag Description/SnagDescription';
import LittleSnagResolution from './Little Snag Resolution/LittleSnagResolution';

export default function SnagPage({ ticket, index, ticketsLength, isLarge }) {
	const { t, i18n } = useTranslation();

	const { properties } = useTicketProperties();
	const [project] = useProjectContext();
	const agencyId = useContext(AgencyContext);
	const [clients] = useClientsContext();
	const type = useContext(TypeContext);

	const [status, setStatus] = useState(null);
	const [images, setImages] = useState([]);

	// ================================================ STATUS ================================================

	useEffect(() => {
		const isClient = type === 'clients' || clients?.map((client) => client.id)?.includes(agencyId);
		if (ticket?.properties?.type === 'issue' && (isClient || agencyId)) {
			setStatus(getTicketStatus(ticket, isClient ? 'clients' : agencyId));
		}
	}, [agencyId, ticket, clients, type]);

	// ================================================ FILES ================================================

	useEffect(() => {
		if (project?.id && ticket?.id) {
			const unsubscribe = firestore
				.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, ticket.id, Paths.CONTENT))
				.where('type', 'in', ['image', 'file'])
				.onSnapshot((querySnapshot) => {
					const images = [];
					querySnapshot.docs.forEach((doc) => {
						if (doc.data().type === 'image') images.push({ ...doc.data(), id: doc.id });
					});
					setImages(images);
				});

			return () => unsubscribe();
		}
	}, [project?.id, ticket?.id]);

	return (
		<div className={'snagPage'}>
			<div
				className={'statusBar'}
				style={
					status === TICKET_STATUS.approved
						? {
								color: '#3CA504',
								backgroundColor: 'rgba(60,165,4,0.1)',
							}
						: { backgroundColor: 'rgba(28, 87, 221, 0.1)', color: '#1C57DD' }
				}>
				{isLarge ? (
					<span className={'ticketNameWebkit'} style={{ width: 300 }}>
						{ticket?.name}
					</span>
				) : (
					<span style={{ width: 200 }}>
						{t('translation.snag')} #{index}
					</span>
				)}
				<span>
					{new Date().toLocaleDateString(i18n.language, {
						month: 'short',
						day: 'numeric',
						year: 'numeric',
					})}
				</span>
				<span style={{ width: 300, display: 'flex', justifyContent: 'flex-end' }}>{project.name}</span>
			</div>

			<div className={'snagDescription'} style={{ height: isLarge ? 1100 : 700, overflow: 'hidden' }}>
				<div className={'containerSnagNameCreator'}>
					<span className={'snagName'}>{ticket?.name}</span>
				</div>

				<div style={{ display: 'flex', alignItems: 'center', marginTop: 15, justifyContent: 'space-between' }}>
					<div className={'generalDatas'}>
						{ticket.properties.lots?.length > 0 &&
							ticket.properties.lots.map((lot, index) => (
								<ItemGeneralData
									name={properties.lots.options.find((option) => option.key === lot)?.label}
									icon={hammer}
								/>
							))}

						{ticket.properties.locations?.length > 0 &&
							ticket.properties.locations.map((location, index) => (
								<ItemGeneralData
									name={properties.locations.options.find((option) => option.key === location)?.label}
									icon={mappin}
								/>
							))}
					</div>

					<div className={'creator'}>
						{t('translation.createdOn')}{' '}
						<span style={{ fontWeight: '700' }}>
							{formatDateString(ticket.properties.issueAt, i18n.language, t)}
						</span>{' '}
						{t('translation.by')}{' '}
						<CompanyOrPersonItem
							userId={ticket.properties.owner.userId}
							userType={ticket.properties.owner.userType}
							agencyId={ticket.properties.owner.agencyId}
							unClickable={true}
							maxWidth={300}
						/>
					</div>
				</div>

				<div className={'containerPictures'}>
					{images.slice(0, 3).map((image) => (
						<img src={image.url} alt="" />
					))}
				</div>

				<SnagDescription isLarge={isLarge} ticket={ticket} />
			</div>

			{!isLarge && <LittleSnagResolution status={status} ticket={ticket} />}
		</div>
	);
}

function ItemGeneralData({ name, icon }) {
	return (
		<div className={'itemGeneralData'}>
			<img src={icon} alt="" className={'icon'} />
			<span>{name}</span>
		</div>
	);
}
