import React, { useContext, useEffect, useRef, useState } from 'react';
import { auth, firestore, storage } from '../../../../firebase/config';
import Resizer from 'react-image-file-resizer';
import ModalModifAlbum from '../Modaux/ModalModifAlbum';
import chargement from '../../../../assets/chargement.png';
import plusSquare from '../../../../assets/plusSquare.svg';
import placeholderPhotoAlbums from '../../../../assets/placeholderPhotoAlbums.png';
import {
	deleteStorageFromUrl,
	generateUniqueFirestoreId,
	getPathStorageFromUrl,
	uploadOnStorage,
} from '../../../../firebase/utils';
import AgencyContext from '../../../../contexts/agencyContext';
import { DropZonePhotos } from '../components/DropZonePhotos';
import { useTranslation } from 'react-i18next';
import AgencyDataContext from '../../../../contexts/agencyDataContext';
import ModalNotYourAlbum from '../Modaux/ModalNotYourAlbum';
import ModalNewView from '../matterport/ModalNewView';
import PhotoItem from '../components/PhotoItem';
import '../_photos.scss';
import { MEDIA_COLLECTION, PROJECT_COLLECTION } from '../../../../firebase/paths';
import VisitDetail from './VisitDetail';
import ModalPhoto from './ModalPhoto';
import HeaderAlbum from './HeaderAlbum';
import ButtonsPhotos from './ButtonsPhotos';
import PlaceholderAlbum from './PlaceholderAlbum';
import MatterportList from '../matterport/MatterportList';
import {
	NotificationSource,
	sendNotificationToAll,
	sendNotificationToClients,
	sendNotificationToCollaborators,
} from '../../Notifications/utils';
import { createdByConstructor } from '../../utils';
import UploadContext from '../../../../contexts/uploadContext';
import AuthDataContext from '../../../../contexts/authDataContext';
import { useProjectContext } from '../../../../contexts/projectContext';
import { usePartnersContext } from '../../../../contexts/partnersContext';
import mixpanel from 'mixpanel-browser';
import { useClientsContext } from '../../../../contexts/clientsContext';

export default function AlbumContent({
	projectId,
	projectImgUrl,
	setDisplayAlbum,
	albumSelected,
	setAlbumSelected,
	nameAlbumSelected,
	setNameAlbumSelected,
	countPhoto,
	setCountPhoto,
	setAlbums,
	setOtherTypeAlbums,
	albumSelectedIndex,
	type,
	partnerAgencies,
	isModeLecture,
	handleModifyLots,
	handleModifyLocations,
}) {
	const { t } = useTranslation();

	const agencyId = useContext(AgencyContext);
	const agencyData = useContext(AgencyDataContext);
	const uid = auth.currentUser?.uid;
	const [_, setUploadProgress] = useContext(UploadContext);
	const authData = useContext(AuthDataContext);
	const [project] = useProjectContext();
	const [partners] = usePartnersContext();

	const [modalNotYourAlbum, setModalNotYourAlbum] = useState(false);

	const isVisit = albumSelected?.type === 'visit';
	const [photos, setPhotos] = useState([]);
	const [lastPhoto, setLastPhoto] = useState();
	const [selectedPhoto, setSelectedPhoto] = useState();
	const [index, setIndex] = useState();
	const [isSelectOption, setIsSelectOption] = useState(false);
	const [selectedPhotos, setSelectedPhotos] = useState([]);
	const [modalPhoto, setModalPhoto] = useState(false);
	const [modalModifAlbum, setModalModifAlbum] = useState(false);
	const [adminView, setAdminView] = useState(false);
	const [modalNewView, setModalNewView] = useState(false);

	const [dataLoading, setDataLoading] = useState(true);
	const [moreDataLoading, setMoreDataLoading] = useState(false);
	const [loadingCouvertureAlbum, setLoadingCouvertureAlbum] = useState(false);
	const [enregistree, setEnregistree] = useState(false);
	const [loadingProjectCover, setLoadingProjectCover] = useState(false);
	const [enregistreeProjectCover, setEnregistreeProjectCover] = useState(false);

	const [dropZoneIsActive, setDropZoneIsActive] = useState(false);

	const [alreadyNotified, setAlreadyNotified] = useState(false);

	// Modal PhotosPage

	const transformWrapperRef = useRef(null);
	const [zoomState, setZoomState] = useState(0);

	const zoomIn = () => {
		if (transformWrapperRef.current) {
			transformWrapperRef.current.zoomIn();
			setZoomState(zoomState + 1);
		}
	};

	const zoomOut = () => {
		if (transformWrapperRef.current) {
			transformWrapperRef.current.zoomOut();
			if (zoomState > 0) {
				setZoomState(zoomState - 1);
			}
		}
	};

	const resetTransform = () => {
		if (transformWrapperRef.current) {
			transformWrapperRef.current.resetTransform();
			if (zoomState > 0) {
				setZoomState(0);
			}
		}
	};

	useEffect(() => {
		if (projectId && albumSelected.id) {
			firestore
				.collection(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}`)
				.where('albumId', '==', albumSelected.id)
				.orderBy('orderDate', 'desc')
				.limit(100)
				.get()
				.then((querySnapshot) => {
					if (querySnapshot) {
						const pictures = [];
						querySnapshot.forEach((documentSnapshot) => {
							if (documentSnapshot && documentSnapshot.exists) {
								pictures.push({ ...documentSnapshot.data(), id: documentSnapshot.id });
								setLastPhoto(documentSnapshot);
							}
						});
						setPhotos(pictures);
						setDataLoading(false);
					}
				});
		}
	}, [projectId, albumSelected.id]);

	function handleScroll(e) {
		const bottom = e.target.scrollHeight - e.target.scrollTop + 10 >= e.target.clientHeight;
		if (bottom && projectId && albumSelected.id && lastPhoto && !moreDataLoading) {
			setMoreDataLoading(true);
			firestore
				.collection(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}`)
				.where('albumId', '==', albumSelected.id)
				.orderBy('orderDate', 'desc')
				.startAfter(lastPhoto)
				.limit(42)
				.get()
				.then((querySnapshot) => {
					if (querySnapshot) {
						const pictures = [];
						querySnapshot.forEach((documentSnapshot) => {
							if (documentSnapshot && documentSnapshot.exists) {
								pictures.push({ ...documentSnapshot.data(), id: documentSnapshot.id });
								setLastPhoto(documentSnapshot);
							}
						});
						setMoreDataLoading(false);
						setPhotos((prevState) => prevState.concat(pictures));
					}
				});
		}
	}

	async function handleDownload(files, wasEmpty, alreadyNotified) {
		if (files) {
			mixpanel.track('Upload Media', {
				'Project ID': projectId,
				Page: 'albums',
				'Sub Page': isVisit ? 'visit' : 'album',
			});

			const newPhotos = Array.from(files).map((file) => {
				const date = new Date().toISOString();
				const tempUrl = URL.createObjectURL(file);

				return {
					id: generateUniqueFirestoreId(),
					albumId: albumSelected.id,
					clientPermission: true,
					createdAt: date,
					createdBy: {
						userId: uid,
						userType: type === 'clients' ? 'client' : 'collaborator',
						agencyId: type === 'clients' ? uid : agencyId,
					},
					deletedAt: null,
					fileType: file.type,
					fileSize: file.size,
					isFavorite: false,
					modifiedAt: date,
					name: file.name,
					orderDate: date,
					permissions: ['all'],
					projectId,
					type: file.type.includes('video') ? 'video' : 'image',
					url: tempUrl,
					thumbnailUrl: null,
					isLoading: true,
					uploadProgress: 0,
					file: file, // We'll remove this before uploading
				};
			});

			setPhotos((prevPhotos) => [...newPhotos.reverse(), ...prevPhotos]);

			const photosUploaded = [];
			const promises = [];
			for (const newPhoto of newPhotos) {
				const { file, ...newPhotoWithoutFile } = newPhoto;
				promises.push(uploadMedia(file, newPhotoWithoutFile, photosUploaded));
			}

			await Promise.all(promises);

			setAlreadyNotified(true);

			if (!alreadyNotified) {
				if (albumSelected.clientPermission) {
					sendNotificationToClients(
						createdByConstructor(uid, type, agencyId),
						projectId,
						NotificationSource.PHOTOS,
						albumSelected,
						wasEmpty
							? [
									{
										...albumSelected,
										thumbnails: photosUploaded.slice(0, 9),
									},
								]
							: [...photosUploaded],
						authData?.surname + ' ' + authData?.name,
						project?.name,
						project?.imgUrl
					);
				}

				if (albumSelected.permissions.includes('all')) {
					if (agencyId) {
						sendNotificationToCollaborators(
							createdByConstructor(uid, type, agencyId),
							agencyId,
							false,
							projectId,
							NotificationSource.PHOTOS,
							albumSelected,
							wasEmpty
								? [
										{
											...albumSelected,
											thumbnails: photosUploaded.slice(0, 9),
										},
									]
								: [...photosUploaded],
							authData?.surname + ' ' + authData?.name,
							project?.name,
							project?.imgUrl
						);
					}

					for (const id of partners.map((item) => item.id)) {
						sendNotificationToCollaborators(
							createdByConstructor(uid, type, agencyId),
							id,
							false,
							projectId,
							NotificationSource.PHOTOS,
							albumSelected,
							wasEmpty
								? [
										{
											...albumSelected,
											thumbnails: photosUploaded.slice(0, 9),
										},
									]
								: [...photosUploaded],
							authData?.surname + ' ' + authData?.name,
							project?.name,
							project?.imgUrl
						);
					}
				} else {
					for (const id of albumSelected.permissions) {
						sendNotificationToCollaborators(
							createdByConstructor(uid, type, agencyId),
							id,
							false,
							projectId,
							NotificationSource.PHOTOS,
							albumSelected,
							wasEmpty
								? [
										{
											...albumSelected,
											thumbnails: photosUploaded.slice(0, 9),
										},
									]
								: [...photosUploaded],
							authData?.surname + ' ' + authData?.name,
							project?.name,
							project?.imgUrl
						);
					}
				}
			}
		}
	}

	function uploadMedia(file, photoData, photosUploaded) {
		return new Promise((resolve, reject) => {
			const upload = (file) =>
				uploadOnStorage(
					photoData.id,
					file,
					photoData,
					`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}`,
					setUploadProgress,
					albumSelected.name
				)
					.then((url) => {
						firestore
							.collection(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}`)
							.doc(photoData.id)
							.set({ ...photoData, url })
							.then(() => {
								setPhotos((prevPhotos) =>
									prevPhotos.map((photo) =>
										photo.id === photoData.id ? { ...photo, url, isLoading: false } : photo
									)
								);
							});
						photosUploaded.push({ ...photoData, url });
						URL.revokeObjectURL(photoData.url); // Libérer la mémoire
						resolve();
					})
					.catch((error) => {
						setPhotos((prevPhotos) => prevPhotos.filter((photo) => photo.id !== photoData.id));
						reject(error);
					});

			if (file.type.includes('video')) {
				upload(file);
			} else {
				Resizer.imageFileResizer(file, 2000, 2000, 'jpeg', 70, 0, (file) => upload(file), 'file');
			}
		});
	}

	async function deletePhoto(photo) {
		setPhotos((oldValue) => {
			const newValue = [...oldValue];
			newValue.splice(newValue.indexOf(photo), 1);
			return newValue;
		});
		await deleteStorageFromUrl(photo.url);
		await deleteStorageFromUrl(photo.thumbnailUrl);
		storage
			.ref(getPathStorageFromUrl(photo.url.replace(photo.id, 'thumb_' + photo.id + '.jpeg')))
			.getDownloadURL()
			.then((url) => {
				deleteStorageFromUrl(url);
			});
		await firestore.doc(`projects/${projectId}/medias/${photo.id}`).delete();
	}

	function downloadPhotos(photos) {
		if (photos && photos.length > 0) {
			const a = document.createElement('a');
			a.href = `https://transfer.opusoft.app/downloadUrls/${encodeURIComponent(
				albumSelected?.name ?? 'album'
			)}/${encodeURIComponent(photos.map((it) => it.url).join(','))}`;
			a.download = albumSelected?.name ?? 'album';
			a.click();
		}
	}

	// Animations

	const [count, setCount] = useState(false);
	const [margin1, setMargin1] = useState(70);
	const [margin2, setMargin2] = useState(70);
	const [opacity2, setOpacity2] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			setCount(true);
		}, 400);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			if (count) {
				setMargin1(0);
				setMargin2(0);
				setOpacity2(1);
			}
		}, 0);
	}, [count]);

	const [opacityPhoto, setOpacityPhoto] = useState(0);

	useEffect(() => {
		if (countPhoto) {
			setOpacityPhoto(1);
		}
	}, [countPhoto]);

	function goBackButtonModalPhoto(e) {
		e.preventDefault();
		setModalPhoto(false);
		setSelectedPhoto(null);
	}

	async function saveAsProjectCover() {
		setLoadingProjectCover(true);
		await firestore.doc(`${PROJECT_COLLECTION}/${projectId}`).update({ imgUrl: selectedPhoto.url });
		setLoadingProjectCover(false);
		setEnregistreeProjectCover(true);
		setTimeout(() => {
			setEnregistreeProjectCover(false);
		}, 3000);
	}

	async function saveAsAlbumCover() {
		const updateAlbum = async () => {
			setLoadingCouvertureAlbum(true);
			await firestore
				.doc(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}/${albumSelected.id}`)
				.update({ url: selectedPhoto.url });
			setAlbums((oldValue) => {
				const newValue = [...oldValue];
				newValue[oldValue.indexOf(albumSelected)] = {
					...albumSelected,
					url: selectedPhoto.url,
				};
				return newValue;
			});
			setAlbumSelected({
				...albumSelected,
				url: selectedPhoto.url,
			});
			setLoadingCouvertureAlbum(false);
			setEnregistree(true);
			setTimeout(() => {
				setEnregistree(false);
			}, 3000);
		};

		if (type === 'clients' && albumSelected.createdBy?.userType === 'client') {
			await updateAlbum();
		} else if (albumSelected.createdBy?.agencyId === agencyId) {
			await updateAlbum();
		}
	}

	async function deletePicture(e) {
		const supprPhoto = async () => {
			e.preventDefault();
			if (window.confirm(t('photos.sure_to_delete'))) {
				setModalPhoto(false);
				await deletePhoto(selectedPhoto);
				setSelectedPhoto(null);
			}
		};

		if (type === 'clients' && selectedPhoto.createdBy?.userType === 'client') {
			await supprPhoto();
		} else if (selectedPhoto.createdBy?.agencyId === agencyId) {
			await supprPhoto();
		}
	}

	function onKeyPressed(e) {
		if (modalPhoto) {
			if (e.keyCode === 37 && index > 0) {
				if (photos && photos[index - 1]) {
					const img = photos && photos[index - 1];
					setSelectedPhoto(img);
					setIndex(index - 1);
				}
			} else if (e.keyCode === 39) {
				if (photos && photos[index + 1]) {
					const img = photos && photos[index + 1];
					setSelectedPhoto(img);
					setIndex(index + 1);
				}
			}
		}
	}

	const exportAlbum = () => {
		if (photos && photos.length > 0) {
			const a = document.createElement('a');
			a.href = `https://transfer.opusoft.app/downloadAlbum/${encodeURIComponent(projectId)}/${albumSelected?.id}`;
			a.click();
			a.remove();
		}
	};

	const [draggedItem, setDraggedItem] = useState(null);
	const [dropIndex, setDropIndex] = useState(null);

	const handleDragStart = (item) => {
		setDraggedItem(item);
		setTimeout(() => {
			setPhotos((prev) => prev.map((p) => (p.id === item.id ? { ...p, isDragged: true } : p)));
		}, 0);
	};

	const handleDragEnd = async () => {
		if (draggedItem && dropIndex !== null) {
			const oldIndex = photos.findIndex((p) => p.id === draggedItem.id);
			const newIndex = dropIndex;

			if (oldIndex !== newIndex) {
				// Create new photos array with updated order
				const newPhotos = [...photos];
				newPhotos.splice(oldIndex, 1);
				if (oldIndex > newIndex) {
					newPhotos.splice(newIndex, 0, draggedItem);
				} else {
					newPhotos.splice(newIndex - 1, 0, draggedItem);
				}

				// Calculate new order dates only for affected photos
				const now = new Date().toISOString();
				const photosWithNewDates = newPhotos.map((photo, index) => {
					// Only update dates for photos up to the new position
					if (index <= newIndex) {
						const orderDate = new Date(now);
						orderDate.setMilliseconds(orderDate.getMilliseconds() - index);
						return {
							...photo,
							isDragged: false,
							orderDate: orderDate.toISOString(),
						};
					}
					return {
						...photo,
						isDragged: false,
					};
				});

				// Update state
				setPhotos(photosWithNewDates);

				// Update Firestore only for affected photos
				const batch = firestore.batch();
				const mediaRef = firestore.collection(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}`);

				photosWithNewDates.forEach((photo, index) => {
					if (index <= newIndex) {
						batch.update(mediaRef.doc(photo.id), {
							orderDate: photo.orderDate,
						});
					}
				});

				await batch.commit();
			} else {
				setPhotos((prev) => prev.map((p) => (p.id === draggedItem.id ? { ...p, isDragged: false } : p)));
			}
		}
		setDraggedItem(null);
		setDropIndex(null);
	};

	const handleDragOver = (overItem) => {
		if (!draggedItem) return;
		const overIndex = photos.findIndex((p) => p.id === overItem.id);
		setDropIndex(overIndex);
	};

	return (
		<div
			className={'photosAlbum'}
			onKeyDown={onKeyPressed}
			tabIndex={0}
			style={{ display: 'flex' }}
			onDragEnter={() => setDropZoneIsActive(!draggedItem)}>
			<div style={{ width: isVisit && 'calc(100vw - 620px)' }}>
				<HeaderAlbum
					setAlbumSelected={setAlbumSelected}
					setDisplayAlbum={setDisplayAlbum}
					setNameAlbumSelected={setNameAlbumSelected}
					albumSelected={albumSelected}
					setModalModifAlbum={setModalModifAlbum}
					setModalNotYourAlbum={setModalNotYourAlbum}
					agencyData={agencyData}
					partnerAgencies={partnerAgencies}
					setDataLoading={setDataLoading}
					setCountPhoto={setCountPhoto}
					isModeLecture={isModeLecture}
				/>

				{albumSelected?.type === 'albumMatterport' ? (
					<div>
						<div style={{ display: 'flex' }}>
							<div className={'longBarAlbum'} />
							<div onClick={() => setModalNewView(true)} className={'containerButtonPhotos'}>
								<div
									style={{ backgroundColor: adminView ? '#daf8d2' : '#eeeeee' }}
									className={'buttonPhoto hover'}>
									<img className={'iconButtonPhoto'} src={plusSquare} alt="iconPlus" />
									<p className={'textButtonPhoto'} style={{ color: adminView ? '#328536' : '#000' }}>
										{t('photos.add_visit')}
									</p>
								</div>
							</div>
						</div>

						{!dataLoading ? (
							photos && photos.length > 0 ? (
								<MatterportList
									adminView={adminView}
									photos={photos}
									setPhotos={setPhotos}
									projectId={projectId}
									albumData={albumSelected}
								/>
							) : (
								<div className="photoList">
									<div className="inner">
										{!dropZoneIsActive ? (
											<div
												style={{
													opacity: opacity2,
												}}
												className={'placeholderAlbums'}>
												<div>
													<img
														src={placeholderPhotoAlbums}
														alt=""
														className={'placeholderPhotoAlbums'}
													/>
													<div style={{ marginLeft: 40 }}>
														<p
															style={{ marginLeft: margin1 }}
															className={'titlePlaceHolder'}>
															{t('photos.add_photos_to_albums')}
														</p>
														<p
															style={{ marginLeft: margin1 }}
															className={'subtitlePlaceHolder'}>
															{t('photos.permit_your_partner_a_overview')}
														</p>
														<p
															style={{ marginLeft: margin1 }}
															className={'subtitlePlaceHolder'}>
															{t('photos.add_thematic_pics')}
														</p>
													</div>
												</div>
											</div>
										) : (
											<div />
										)}
									</div>
								</div>
							)
						) : (
							<div className={'chargementContainer'} style={{ marginTop: 200 }}>
								<img src={chargement} alt="" className={'chargement'} />
							</div>
						)}
					</div>
				) : (
					<div
						onDragEnter={() => setDropZoneIsActive(!draggedItem)}
						onDrop={() => setDropZoneIsActive(false)}
						onClick={() => setDropZoneIsActive(false)}>
						{!isModeLecture && (
							<DropZonePhotos
								isActive={dropZoneIsActive}
								handleDownload={handleDownload}
								isVisit={isVisit}
								wasEmpty={photos.length === 0}
								alreadyNotified={alreadyNotified}
							/>
						)}

						<div style={{ display: 'flex' }}>
							<div className={'longBarAlbum'} style={{ width: isVisit && 'calc(100vw - 650px)' }} />

							{!isModeLecture && (
								<ButtonsPhotos
									type={type}
									setIsSelectOption={setIsSelectOption}
									setSelectedPhotos={setSelectedPhotos}
									isSelectOption={isSelectOption}
									selectedPhotos={selectedPhotos}
									deletePhoto={deletePhoto}
									downloadPhotos={downloadPhotos}
									noPictures={photos && photos.length === 0}
									exportAlbum={exportAlbum}
								/>
							)}

							<input
								type="file"
								id="input"
								accept="image/*,video/*,.heic, .heif"
								multiple={true}
								onChange={(e) => handleDownload(e.target?.files, photos.length === 0, alreadyNotified)}
							/>
						</div>

						{!dataLoading ? (
							<div className="photoList">
								<div
									className={'inner'}
									onScroll={handleScroll}
									style={{ width: isVisit && 'calc(100vw - 655px)' }}>
									{photos && photos.length > 0 ? (
										<div className="photos-grid">
											{photos.map((value, index) => (
												<React.Fragment key={`item-${value.id}`}>
													{!isSelectOption &&
														draggedItem &&
														dropIndex === index &&
														draggedItem?.id !== value.id && (
															<div className="photos-grid__drop-indicator" />
														)}
													<PhotoItem
														item={value}
														setIndex={setIndex}
														photos={photos}
														selectedPhotos={selectedPhotos}
														setSelectedPhotos={setSelectedPhotos}
														setSelectedPhoto={setSelectedPhoto}
														isSelectOption={isSelectOption}
														setModalPhoto={setModalPhoto}
														setDropZoneIsActive={setDropZoneIsActive}
														opacityPhoto={opacityPhoto}
														agencyData={agencyData}
														partnerAgencies={partnerAgencies}
														onDragStart={handleDragStart}
														onDragEnd={handleDragEnd}
														onDragOver={handleDragOver}
														draggedItem={draggedItem}
													/>
												</React.Fragment>
											))}
										</div>
									) : !dropZoneIsActive ? (
										<PlaceholderAlbum
											isModeLecture={isModeLecture}
											opacity2={opacity2}
											t={t}
											margin1={margin1}
											margin2={margin2}
											newPhoto={(e) =>
												handleDownload(e.target?.files, photos.length === 0, alreadyNotified)
											}
										/>
									) : (
										<div />
									)}
								</div>
							</div>
						) : (
							<div className={'chargementContainer'} style={{ marginTop: 200 }}>
								<img src={chargement} alt="" className={'chargement'} />
							</div>
						)}
					</div>
				)}

				<ModalPhoto
					modalPhoto={modalPhoto}
					selectedPhoto={selectedPhoto}
					setSelectedPhoto={setSelectedPhoto}
					photos={photos}
					index={index}
					setIndex={setIndex}
					zoomIn={zoomIn}
					zoomOut={zoomOut}
					resetTransform={resetTransform}
					transformWrapperRef={transformWrapperRef}
					goBackButtonModalPhoto={goBackButtonModalPhoto}
					albumSelected={albumSelected}
					agencyData={agencyData}
					partnerAgencies={partnerAgencies}
					type={type}
					saveAsAlbumCover={saveAsAlbumCover}
					deletePicture={deletePicture}
					loadingCouvertureAlbum={loadingCouvertureAlbum}
					enregistree={enregistree}
					saveAsProjectCover={saveAsProjectCover}
					loadingProjectCover={loadingProjectCover}
					enregistreeProjectCover={enregistreeProjectCover}
					downloadPhotos={downloadPhotos}
					setPhotos={setPhotos}
					isModeLecture={isModeLecture}
					handleModifyLots={handleModifyLots}
					handleModifyLocations={handleModifyLocations}
				/>

				<ModalModifAlbum
					type={type}
					modalModifAlbum={modalModifAlbum}
					setModalModifAlbum={setModalModifAlbum}
					selectedAlbum={albumSelected}
					setAlbumSelected={setAlbumSelected}
					projectId={projectId}
					projectImgUrl={projectImgUrl}
					setDisplayAlbum={setDisplayAlbum}
					setNameAlbumSelected={setNameAlbumSelected}
					setAlbums={setAlbums}
					selectedAlbumIndex={albumSelectedIndex}
					isVisit={isVisit}
					setOtherTypeAlbums={setOtherTypeAlbums}
				/>

				<ModalNotYourAlbum
					modalNotYourAlbum={modalNotYourAlbum}
					setModalNotYourAlbum={setModalNotYourAlbum}
					albumSelected={albumSelected}
					nameAlbumSelected={nameAlbumSelected}
					partnerAgencies={partnerAgencies}
				/>

				<ModalNewView
					modalNewView={modalNewView}
					setModalNewView={setModalNewView}
					albumData={albumSelected}
					projectId={projectId}
					setPhotos={setPhotos}
				/>
			</div>

			<div>
				{isVisit && (
					<VisitDetail
						projectId={projectId}
						projectImgUrl={projectImgUrl}
						visit={albumSelected}
						setVisits={setAlbums}
					/>
				)}
			</div>
		</div>
	);
}
