import React, { useContext, useMemo, useState } from 'react';
import clock from '../../../assets/clock.svg';
import hourglass from '../../../assets/hourglass.svg';
import twoPeopleIcon from '../../../assets/twoPeopleIcon.svg';
import mappin from '../../../assets/mappin.svg';
import Building from '../../../assets/Building.svg';
import hammer from '../../../assets/hammer.svg';
import eye from '../../../assets/eye.svg';
import { useTranslation } from 'react-i18next';
import CompanyOrPersonItem from './components/Little Components/Company Or Person Item/CompanyOrPersonItem.js';
import { parseISO } from 'date-fns';
import AgencyContext from '../../../contexts/agencyContext';
import { useProjectLots } from '../../../contexts/projectLotsContext.js';
import { useProjectLocations } from '../../../contexts/projectLocationsContext.js';
import { firestore } from '../../../firebase/config.js';
import { joinPaths } from '../../../firebase/utils.js';
import { Paths } from '../../../firebase/paths.js';
import { useProjectContext } from '../../../contexts/projectContext.js';
import { usePartnersContext } from '../../../contexts/partnersContext.js';
import AgencyDataContext from '../../../contexts/agencyDataContext.js';
import { useProjectUsersContext } from '../../../contexts/projectUsersContext.js';
import { useClientsContext } from 'src/contexts/clientsContext';
import TypeContext from 'src/contexts/typeContext';
import { noLevelId } from '../Settings Projet/Sub Pages/OnSiteLocations';

export const MISSIONS_STATUS = {
	ongoing: 'ongoing',
	finished: 'finished',
};

export const TICKET_LOG_TYPE = {
	created: 'created',
	opened: 'opened',
	solved: 'solved',
	approved: 'approved',
	refused: 'refused',
};

export const useTicketProperties = () => {
	const { t, i18n } = useTranslation();

	const agencyId = useContext(AgencyContext);
	const agencyData = useContext(AgencyDataContext);
	const [partners] = usePartnersContext();
	const [clients] = useClientsContext();
	const [project] = useProjectContext();
	const [lots] = useProjectLots();
	const [locations] = useProjectLocations();
	const [users] = useProjectUsersContext();
	const userType = useContext(TypeContext);

	const properties = useMemo(() => {
		return {
			visibility: {
				key: 'visibility',
				label: t('translation.visibility'),
				icon: eye,
				type: 'select',
				fixed: true,
				options: [
					{
						key: 'collaborative',
						label: t('translation.collaborative'),
						backgroundColor: '#e4f4e0',
						color: '#2d4c23',
					},
					{
						key: 'private',
						label: t('translation.private'),
						backgroundColor: '#eaeaea',
						color: '#3f3f3f',
						withToDo: true,
					},
				],
				render: (options, value) => {
					const option = options?.find((option) => option.key === value) || { label: 'Unknown' };

					return (
						<div className={'containerValueItemEmpty'}>
							<div className={`valueItemType type${option.key}`}>{option.label}</div>
						</div>
					);
				},
			},

			lots: {
				key: 'lots',
				label: t('translation.lotsLinked'),
				icon: hammer,
				type: 'select',
				multiple: true,
				modifyModal: true,
				options: lots?.map((lot) => ({ key: lot.id, label: `Lot ${lot.index} • ${lot.name}` })),
				render: (options, value) => {
					const values = options.filter((option) => value.includes(option.key));

					return (
						<div className={`containerValueItem`}>
							{values.map((tag, index) => (
								<div className={`valueItemTags`} key={index}>
									{tag.label}
								</div>
							))}
						</div>
					);
				},
			},
			locations: {
				key: 'locations',
				label: t('translation.locationOnSite'),
				icon: mappin,
				type: 'locations',
				multiple: false,
				grouped: true,
				options: locations?.map((location) => ({
					key: location.id,
					label: location.name,
					type: location.type,
					levelId: location.levelId,
				})),
				optionsGrouped:
					locations
						?.filter((location) => location.type !== 'level')
						?.reduce((groups, location) => {
							const level = locations.find((l) => l.id === location.levelId);
							const key = level ? (level.id ?? noLevelId) : noLevelId;

							if (!groups[key]) {
								groups[key] = {
									id: key,
									key: key,
									name:
										key === noLevelId
											? t('translation.noLevel')
											: locations.find((l) => l.id === key)?.name || '',
									label:
										key === noLevelId
											? t('translation.noLevel')
											: locations.find((l) => l.id === key)?.name || '',
									locations: [],
								};
							}

							groups[key].locations = [
								...groups[key].locations,
								{ ...location, key: location.id, label: location.name },
							];

							return groups;
						}, {}) || {},
				render: (options, value) => {
					const values = options.filter((option) => value.includes(option.key));
					const levels = options.filter((option) => option.type === 'level');

					return (
						<div className={`containerValueItem`}>
							{values.map((tag, index) => (
								<div className={`valueItemTags`} key={index}>
									{tag.type !== 'level' && levels.find((level) => level.key === tag.levelId)
										? levels.find((level) => level.key === tag.levelId).label + ' • '
										: ''}
									{tag.label}
								</div>
							))}
						</div>
					);
				},
			},
			owner: {
				key: 'owner',
				label: t('translation.ticketOwner'),
				icon: twoPeopleIcon,
				type: 'people',
				fixed: true,
				render: (options, value) => {
					return (
						<div className={`containerValueItemFixed`} style={{ paddingTop: 0 }}>
							<CompanyOrPersonItem
								userId={
									value?.userType === 'client' ||
									clients?.map((client) => client.id)?.includes(value?.agencyId)
										? 'clients'
										: value?.userId
								}
								unClickable={true}
							/>
						</div>
					);
				},
			},
			companies: {
				key: 'companies',
				label: t('translation.companyInCharge'),
				icon: Building,
				type: 'company',
				multiple: true,
				options: [
					...(agencyId ? [{ key: agencyId, label: agencyData?.name }] : []),
					...(partners || []).map((partner) => ({ key: partner.id, label: partner.name })),
				],
				render: (options, value) => {
					return (
						<div className={`containerValueItem`} style={{ paddingTop: 0 }}>
							{value.map((company, index) => (
								<CompanyOrPersonItem key={index} agencyId={company} company={true} />
							))}
						</div>
					);
				},
			},
			reviewers: {
				key: 'reviewers',
				label: t('translation.reviewers'),
				icon: Building,
				type: 'company',
				multiple: true,
				options: [
					...(userType === 'clients' || clients?.map((client) => client.id)?.includes(agencyId)
						? []
						: [{ key: 'clients', label: t('translation.mo') }]),
					...(agencyId ? [{ key: agencyId, label: agencyData?.name }] : []),
					...(partners || []).map((partner) => ({ key: partner.id, label: partner.name })),
				],
				render: (options, value) => {
					return (
						<div className={`containerValueItem`} style={{ paddingTop: 0 }}>
							{value.map((company) =>
								company === 'clients' ? (
									<CompanyOrPersonItem key={'clients'} userId={'clients'} />
								) : (
									<CompanyOrPersonItem key={company} agencyId={company} company={true} />
								)
							)}
						</div>
					);
				},
			},
			users: {
				key: 'users',
				label: t('translation.sharedWith'),
				icon: twoPeopleIcon,
				type: 'people',
				multiple: true,
				options: [
					...(userType === 'clients' || clients?.map((client) => client.id)?.includes(agencyId)
						? []
						: [{ key: 'clients', label: t('translation.mo') }]),
					...(users
						?.filter((user) => user.type !== 'clients')
						?.map((user) => ({
							key: user.id,
							label: user.surname + ' ' + user.name,
							companyId: user.agency,
						})) ?? []),
				],
				render: (options, value) => {
					return (
						<div className={`containerValueItem`} style={{ paddingTop: 0 }}>
							{value.map((people) =>
								people === 'clients' ? (
									<CompanyOrPersonItem key={'clients'} userId={'clients'} />
								) : (
									<CompanyOrPersonItem key={people} userId={people} />
								)
							)}
						</div>
					);
				},
			},
			issueAt: {
				key: 'issueAt',
				label: t('translation.startDate'),
				icon: clock,
				type: 'date',
				render: (options, value) => {
					const date = parseISO(value);

					return (
						<div className={`containerValueItemEmpty`}>
							<div className={`valueItemissueDate`} style={{ color: '#000' }}>
								{date?.toLocaleDateString(i18n.language)}
							</div>
						</div>
					);
				},
			},
			dueAt: {
				key: 'dueAt',
				label: t('translation.dueDate'),
				icon: clock,
				type: 'date',
				render: (options, value) => {
					const date = parseISO(value);
					return (
						<div className={`containerValueItemEmpty`}>
							<div className={`valueItemdueDate`} style={{ color: '#000' }}>
								{date?.toLocaleDateString(i18n.language)}
							</div>
						</div>
					);
				},
			},
			expectingDuration: {
				key: 'expectingDuration',
				label: t('translation.workDuration'),
				icon: hourglass,
				type: 'text',
				render: (options, value) => {
					return (
						<div className={`containerValueItemEmpty`}>
							<div className={`valueItemexpectingDuration`} style={{ color: '#000' }}>
								{value}
							</div>
						</div>
					);
				},
			},
		};
	}, [agencyId, agencyData, clients, partners, users, project, lots, locations]);

	return { properties };
};
