import React, { useEffect, useState, useRef } from 'react';
import './_tableComponent.scss';
import plusIcon from '../../assets/plusIcon.svg';
import triangle from '../../assets/triangle.svg';
import blueCheck from '../../assets/blueCheck.svg';
import squareBlack from '../../assets/squareBlack.svg';
import trashIcon from '../../assets/trashIcon.svg';
import pencilBlack from '../../assets/pencilBlack.svg';
import PropTypes from 'prop-types';
import { normalizeString, wait } from 'src/useful/UsefulFunctions';
import CompanyOrPersonItem from 'src/pages/Fiche Projet/Tickets/components/Little Components/Company Or Person Item/CompanyOrPersonItem';
import { useTranslation } from 'react-i18next';
import ModalTyping from '../ModalTyping/ModalTyping';

Table.propTypes = {
	columns: PropTypes.array.isRequired,
	rows: PropTypes.array.isRequired,
	handleNewElem: PropTypes.func.isRequired,
	handleDeleteRows: PropTypes.func.isRequired,
	isGroup: PropTypes.bool,
	titleGroup: PropTypes.string,
	handleDeleteGroup: PropTypes.func,
	handleRenameGroup: PropTypes.func,
};

export default function Table({
	columns,
	rows,
	handleNewElem,
	handleDeleteRows,
	isGroup = false,
	titleGroup = '',
	handleDeleteGroup = null,
	handleRenameGroup = null,
}) {
	const { t } = useTranslation();

	const [collapsed, setCollapsed] = React.useState(false);
	const [shouldFocus, setShouldFocus] = useState(false);
	const [selectedRows, setSelectedRows] = useState([]);
	const [modalRenameGroup, setModalRenameGroup] = useState(false);

	const latestRowRef = useRef(null);

	useEffect(() => {
		if (latestRowRef.current && shouldFocus) {
			const inputs = latestRowRef.current.querySelectorAll('input:not([disabled]), select:not([disabled])');
			if (inputs.length > 0) {
				inputs[0].focus();
			}
			setShouldFocus(false);
		}
	}, [rows.length, shouldFocus]);

	const handleNewElemWithFocus = () => {
		setShouldFocus(true);
		handleNewElem();
	};

	const handleDelete = () => {
		if (window.confirm(t('translation.areYouSureYouWantToDeleteTheseElements'))) {
			handleDeleteRows(selectedRows);
			setSelectedRows([]);
		}
	};

	const handleSelect = (id) => {
		setSelectedRows((prev) => {
			if (prev.includes(id)) {
				return prev.filter((rowId) => rowId !== id);
			} else {
				return [...prev, id];
			}
		});
	};

	const handleSelectAll = () => {
		setSelectedRows((prev) => (prev.length === rows.length ? [] : rows.map((row) => row.id)));
	};

	const handleClickDeleteGroup = () => {
		if (window.confirm(t('translation.areYouSureYouWantToDeleteThisElement'))) {
			handleDeleteGroup();
		}
	};

	const handleClickRenameGroup = () => {
		setModalRenameGroup(true);
	};

	return (
		<div className={'tableComponent'}>
			{selectedRows.length > 0 && (
				<div className={'backgroundContainerOptionsSelect'}>
					<div className={'containerOptionsSelect'}>
						<div className={'elemOptionSelect'} onClick={() => setSelectedRows([])}>
							<p style={{ color: '#2483E2' }}>
								{t(selectedRows.length > 1 ? 'translation.selected_plural' : 'translation.selected', {
									count: selectedRows.length,
								})}
							</p>
						</div>
						<div className={'littleBar'} />
						<div className={'buttonOptionSelect'} onClick={handleDelete}>
							<img src={trashIcon} alt="" className={'trashIcon'} />
							<p>{t('translation.delete')}</p>
						</div>
					</div>
				</div>
			)}

			{isGroup && (
				<div className={'headerGroup'}>
					<div className={'containerTriangle'} onClick={() => setCollapsed(!collapsed)}>
						<img
							src={triangle}
							alt=""
							className={'triangle'}
							style={{ transform: collapsed && 'rotate(-90deg)' }}
						/>
					</div>
					<p className={'titleHeader'}>{titleGroup ? titleGroup : 'Group Title'}</p>
					<p className={'countElems'}>{rows.length}</p>
					{handleRenameGroup && (
						<div className={'containerButtonGroup'} onClick={handleClickRenameGroup}>
							<img src={pencilBlack} alt="" className={'pencilBlack'} />
						</div>
					)}
					{handleDeleteGroup && (
						<div className={'containerButtonGroup'} onClick={handleClickDeleteGroup}>
							<img src={trashIcon} alt="" className={'pencilBlack'} />
						</div>
					)}
				</div>
			)}

			<div className={'barTable'} />

			{!collapsed && (
				<>
					<div className={'headerTable'}>
						{rows.length > 0 && (
							<div
								className={'containerSquareBlack'}
								onClick={handleSelectAll}
								style={{
									opacity: selectedRows.length > 0 && selectedRows.length === rows.length && 1,
								}}>
								<img
									src={
										selectedRows.length > 0 && selectedRows.length === rows.length
											? blueCheck
											: squareBlack
									}
									alt=""
									className={'squareBlack'}
								/>
							</div>
						)}
						{columns.map((column, index) => (
							<Cell key={index} type={'text'} value={column.label} disabled={true} />
						))}
					</div>
					<div className={'barTable'} />

					{rows.map((row, index) => (
						<Row
							key={row.id}
							row={row.content}
							columns={columns}
							ref={index === rows.length - 1 ? latestRowRef : null}
							isSelected={selectedRows.includes(row.id)}
							handleSelect={() => handleSelect(row.id)}
						/>
					))}

					<NewElem handleNewElem={handleNewElemWithFocus} />
				</>
			)}

			<ModalTyping
				modalTyping={modalRenameGroup}
				setModalTyping={setModalRenameGroup}
				placeholder={'Name'}
				title={'Rename'}
				func={handleRenameGroup}
				defaultValue={titleGroup}
			/>
		</div>
	);
}

const Row = React.forwardRef(function Row({ row, columns, isSelected, handleSelect }, ref) {
	return (
		<div
			className={'itemTable'}
			draggable={true}
			ref={ref}
			style={{ backgroundColor: isSelected && '#E2EDFB', borderBottomColor: isSelected && '#CFDAE6' }}>
			<div className={'containerSquareBlack'} onClick={handleSelect} style={{ opacity: isSelected && 1 }}>
				<img src={isSelected ? blueCheck : squareBlack} alt="" className={'squareBlack'} />
			</div>
			{row.map((cell, index) => (
				<Cell
					key={index}
					type={columns[index].type}
					value={cell.value}
					disabled={columns[index].disabled || cell.disabled}
					options={cell.options}
					onValidate={cell.onValidate}
					transform={columns[index].transform}
					isSelected={isSelected}
					onCreate={cell.onCreate}
					optionType={cell.optionType}
				/>
			))}
		</div>
	);
});

function Cell({
	type,
	value,
	disabled = false,
	options = [],
	optionType = null,
	onValidate = (value) => {},
	transform = (value) => value,
	isSelected = false,
	onCreate = null,
}) {
	const { t } = useTranslation();

	const [inputValue, setInputValue] = useState(value);

	const [dropDownShown, setDropDownShown] = useState(false);
	const [dropDownResearch, setDropDownResearch] = useState('');

	const dropDownRef = useRef(null);
	const dropDownResearchRef = useRef(null);

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	useEffect(() => {
		const handleClickOutside = (e) => {
			if (dropDownShown && dropDownRef.current && !dropDownRef.current.contains(e.target)) {
				setDropDownShown(false);
			}
		};
		document.addEventListener('click', handleClickOutside);
		return () => document.removeEventListener('click', handleClickOutside);
	}, [dropDownRef, dropDownShown]);

	const onChange = (e) => {
		const newValue = transform(e.target.value);
		if (value?.startsWith('#') && !newValue?.startsWith('#')) {
			setInputValue('#' + newValue);
		} else {
			setInputValue(newValue);
		}
	};

	const onBlur = (e) => {
		if (e.target.value !== value) {
			onValidate(e.target.value);
		}
	};

	const onKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.target.blur();
		} else if (e.key === 'Escape') {
			e.target.value = value;
			e.target.blur();
		}
	};

	const onDropDownClick = (e) => {
		setDropDownShown(true);
		wait(100).then(() => {
			dropDownResearchRef?.current?.focus();
		});
	};

	const onResearchKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.target.blur();
			onCreate(e.target.value);
		}
	};

	switch (type) {
		case 'text':
			return (
				<div className={'itemCell'} style={{ borderRightColor: isSelected && '#CFDAE6' }}>
					<input
						type="text"
						value={inputValue}
						disabled={disabled}
						onChange={onChange}
						onBlur={onBlur}
						onKeyDown={onKeyDown}
					/>
				</div>
			);
		case 'number':
			return (
				<div className={'itemCell'} style={{ borderRightColor: isSelected && '#CFDAE6' }}>
					<input
						type="number"
						value={inputValue}
						disabled={disabled}
						onChange={onChange}
						onBlur={onBlur}
						onKeyDown={onKeyDown}
					/>
				</div>
			);
		case 'date':
			return (
				<div className={'itemCell'} style={{ borderRightColor: isSelected && '#CFDAE6' }}>
					<input
						type="date"
						value={inputValue}
						disabled={disabled}
						onChange={onChange}
						onBlur={onBlur}
						onKeyDown={onKeyDown}
					/>
				</div>
			);
		case 'select':
		case 'select-multiple':
			return (
				<div
					className={'itemCell'}
					style={{ borderRightColor: isSelected && '#CFDAE6' }}
					onClick={!disabled ? onDropDownClick : undefined}
					ref={dropDownRef}>
					<div className={dropDownShown ? 'itemCell__input inputDropDownVisible' : 'itemCell__input'}>
						<p>{inputValue.map((option) => options.find((o) => o.key === option)?.label).join(', ')}</p>
					</div>

					{dropDownShown && (
						<div className={'inputDropDownTable'}>
							<div className={'containerInputDropDown'}>
								<input
									ref={dropDownResearchRef}
									className="inputDropDown"
									type={'text'}
									placeholder={
										onCreate ? t('translation.researchOrCreate') : t('translation.research')
									}
									value={dropDownResearch}
									onChange={(e) => setDropDownResearch(e.target.value)}
									onKeyDown={onResearchKeyDown}
								/>
							</div>

							<div className={'list'}>
								{onCreate &&
								options?.filter((company) =>
									normalizeString(company?.label).includes(normalizeString(dropDownResearch))
								)?.length === 0 ? (
									<div className={'enterToCreate'}>
										{t('translation.enterToCreate', {
											name: dropDownResearch,
										})}
									</div>
								) : (
									<p className={'subtitleDropDown'}>
										{type === 'select-multiple'
											? t('translation.selectOneOrMoreElements')
											: t('translation.selectOneElement')}
									</p>
								)}
								<div style={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
									{options
										?.filter((company) =>
											normalizeString(company?.label).includes(normalizeString(dropDownResearch))
										)
										?.map(
											(option) =>
												option && (
													<CompanyOrPersonItem
														key={option.key}
														company={optionType === 'companies'}
														level={optionType === 'levels'}
														name={option.label}
														isSelected={inputValue?.includes(option.key)}
														onClick={() => {
															if (type === 'select-multiple') {
																setInputValue((prev) => {
																	const newArray = [...prev];
																	if (newArray.includes(option.key)) {
																		newArray.splice(
																			newArray.indexOf(option.key),
																			1
																		);
																	} else {
																		newArray.push(option.key);
																	}
																	return newArray;
																});
															} else {
																setDropDownShown(false);
																setInputValue([option.key]);
															}
															onValidate(option.key);
														}}
													/>
												)
										)}
								</div>
							</div>
						</div>
					)}
				</div>
			);
		default:
			return (
				<div className={'itemCell'} style={{ borderRightColor: isSelected && '#CFDAE6' }}>
					<input
						type="text"
						value={inputValue}
						disabled={disabled}
						onChange={onChange}
						onBlur={onBlur}
						onKeyDown={onKeyDown}
					/>
				</div>
			);
	}
}

function NewElem({ handleNewElem }) {
	const { t } = useTranslation();

	return (
		<div className={'newElem'} onClick={handleNewElem}>
			<img src={plusIcon} className={'plusIcon'} />
			{t('translation.newElement')}
		</div>
	);
}

export function NewGroup({ createGroup, title = null }) {
	const { t } = useTranslation();

	const [modalTyping, setModalTyping] = useState(false);

	return (
		<>
			<div className={'newGroup'} onClick={() => setModalTyping(true)}>
				<img src={plusIcon} />
				{title ?? t('translation.addAGroup')}
			</div>

			<ModalTyping
				modalTyping={modalTyping}
				setModalTyping={setModalTyping}
				placeholder={t('translation.name')}
				title={title ?? t('translation.addAGroup')}
				func={createGroup}
			/>
		</>
	);
}

Cell.propTypes = {
	type: PropTypes.string.isRequired,
	value: PropTypes.any.isRequired,
	disabled: PropTypes.bool,
	options: PropTypes.array,
	optionType: PropTypes.string,
	onValidate: PropTypes.func,
	transform: PropTypes.func,
	isSelected: PropTypes.bool,
	onCreate: PropTypes.func,
};

NewElem.propTypes = {
	handleNewElem: PropTypes.func.isRequired,
};

NewGroup.propTypes = {
	createGroup: PropTypes.func.isRequired,
	title: PropTypes.string,
};
