import React, { useState } from 'react';
import './_ticketProperties.scss';
import InputModalTicket from '../Input Modal Ticket/InputModalTicket';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export default function TicketPropertyItem({
	property,
	value,
	onChange,
	handleModifyLots,
	handleModifyLocations,
	ticket,
	canModifyTicket,
}) {
	const { t } = useTranslation();

	const [isOpen, setIsOpen] = useState(false);

	const handleClick = () => {
		if (!property.fixed || property.type === 'select') {
			setIsOpen((prev) => !prev);
		}
	};

	return (
		<div style={canModifyTicket ? {} : { pointerEvents: 'none' }}>
			{isOpen && (
				<InputModalTicket
					onClose={() => setIsOpen(false)}
					property={property}
					value={value}
					onChange={onChange}
					ticket={ticket}
					handleModifyLots={handleModifyLots}
					handleModifyLocations={handleModifyLocations}
				/>
			)}
			<div style={{ display: 'flex' }}>
				<div style={{ display: 'flex', alignItems: 'center', height: 33 }}>
					<img src={property.icon} alt="" className={'propertyIcon'} />
					<div className={'designantProperty'}>{property.label}</div>
				</div>

				<div onClick={handleClick}>
					{!value || value.length === 0 ? (
						<div className={'containerValueItemEmpty'}>{t('translation.empty')}</div>
					) : (
						property.render(property.options, value)
					)}
				</div>
			</div>
		</div>
	);
}

TicketPropertyItem.propTypes = {
	property: PropTypes.object.isRequired,
	value: PropTypes.any,
	onChange: PropTypes.func,
	handleModifyLots: PropTypes.func,
	handleModifyLocations: PropTypes.func,
	ticket: PropTypes.object,
	canModifyTicket: PropTypes.bool,
};
