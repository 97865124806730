import React, { useEffect, useState } from 'react';
import '../_subPageSettingsProject.scss';
import replaceIcon from '../../../../../assets/replaceIcon.svg';
import copy from '../../../../../assets/copy.svg';
import checkVert from '../../../../../assets/checkVert.svg';
import PropTypes from 'prop-types';

InputSettings.propTypes = {
	type: PropTypes.string.isRequired,
	name: PropTypes.string,
	defaultValue: PropTypes.string,
	disabled: PropTypes.bool,
	onValidate: PropTypes.func.isRequired,
};

export default function InputSettings({ type, name, defaultValue, disabled, onValidate }) {
	const [value, setValue] = useState(defaultValue);
	const [isCopied, setIsCopied] = useState(false);

	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	function handleCopyId() {
		navigator.clipboard.writeText(value).then(() => {
			setIsCopied(true);
			setTimeout(() => {
				setIsCopied(false);
			}, 4000);
		});
	}

	return (
		<div className={'inputSettings'}>
			<p className={'titleInput'}>{name}</p>
			{type === 'text' && (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<input
						className={disabled ? 'inputTextSettings id' : 'inputTextSettings'}
						type="text"
						value={value}
						disabled={disabled}
						onChange={(e) => setValue(e.target.value)}
						onKeyDown={async (e) => {
							if (e.key === 'Enter') {
								e.preventDefault();
								e.target.blur();
							}
						}}
						onBlur={async (e) => {
							if (!disabled) {
								await onValidate(e);
							}
						}}
					/>
					{disabled && (
						<div
							className={'copyIcon'}
							onClick={handleCopyId}
							style={{ transform: isCopied ? 'scale(1.5)' : 'scale(1)' }}>
							<img
								src={isCopied ? checkVert : copy}
								alt=""
								className={'checkVertIcon'}
								style={{ opacity: isCopied ? 1 : 0.5 }}
							/>
						</div>
					)}
				</div>
			)}

			{type === 'image' && (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<label htmlFor={'input'}>
						<img src={defaultValue} alt={''} className={'projectPicture'} />
					</label>
					<label htmlFor={'input'}>
						<div className={'containerReplaceIcon'}>
							<img src={replaceIcon} alt="" className={'replaceIcon'} />
						</div>
					</label>

					<input
						type="file"
						id="input"
						accept="image/*,.heic,.heif"
						onChange={async (e) => {
							if (!disabled) {
								await onValidate(e);
							}
						}}
					/>
				</div>
			)}
		</div>
	);
}
