import React, { useState } from 'react';
import './_subPageSettingsProject.scss';
import Table from '../../../../components/Table/Table';
import mappin from '../../../../assets/mappin.svg';
import { useProjectLots } from 'src/contexts/projectLotsContext';
import { firestore } from 'src/firebase/config';
import { useProjectContext } from 'src/contexts/projectContext';
import { generateUniqueFirestoreId } from 'src/firebase/utils';
import { usePartnersContext } from 'src/contexts/partnersContext';
import { arrayRemove, arrayUnion } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

export default function Lots() {
	const { t } = useTranslation();

	const propertiesLots = [
		{
			type: 'text',
			label: t('translation.label'),
			icon: mappin,
			transform: (value) => {
				const alphanumeric = value.trim().replace(/[^a-zA-Z0-9]/g, '');
				return alphanumeric.toUpperCase();
			},
		},
		{
			type: 'text',
			label: t('translation.name'),
			icon: mappin,
		},
		{
			type: 'select-multiple',
			label: t('translation.companiesLinked'),
			icon: mappin,
		},
	];

	const [project] = useProjectContext();
	const [lots, setLots] = useProjectLots();
	const [partners] = usePartnersContext();

	const handleNewElem = async () => {
		setLots((prevState) => [
			...prevState,
			{ id: generateUniqueFirestoreId(), index: '', name: '', companiesLinked: [] },
		]);
	};

	const handleIndexChange = async (lotId, value) => {
		await firestore
			.collection('projects')
			.doc(project.id)
			.collection('lots')
			.doc(lotId)
			.set(
				{
					index: value.replace('#', ''),
				},
				{ merge: true }
			);
	};

	const handleNameChange = async (lotId, value) => {
		await firestore
			.collection('projects')
			.doc(project.id)
			.collection('lots')
			.doc(lotId)
			.set({ name: value?.trim() || '' }, { merge: true });
	};

	const handleCompaniesLinkedChange = async (lotId, value) => {
		const isDeletion = lots.find((lot) => lot.id === lotId).companies?.includes(value);

		await firestore
			.collection('projects')
			.doc(project.id)
			.collection('lots')
			.doc(lotId)
			.set({ companies: isDeletion ? arrayRemove(value) : arrayUnion(value) }, { merge: true });
	};

	const handleDeleteLots = async (ids) => {
		const batch = firestore.batch();

		ids.forEach((id) => {
			batch.update(firestore.collection('projects').doc(project.id).collection('lots').doc(id), {
				isDeleted: true,
			});
		});

		await batch.commit();
	};

	return (
		<div className={'subPageSettingsProject'}>
			<p className={'titleSubPage'}>{t('translation.lotsOfTheProject')}</p>
			<p className={'subtitleSubPage'}>{t('translation.subtitleLotsOfTheProject')}</p>
			<Table
				columns={propertiesLots}
				rows={lots.map((lot) => ({
					id: lot.id,
					content: [
						{ value: `#${lot.index}`, onValidate: (value) => handleIndexChange(lot.id, value) },
						{ value: lot.name, onValidate: (value) => handleNameChange(lot.id, value) },
						{
							value: lot.companies || [],
							onValidate: (value) => handleCompaniesLinkedChange(lot.id, value),
							options: partners?.map((partner) => ({ label: partner.name, key: partner.id })),
							optionType: 'companies',
						},
					],
				}))}
				handleNewElem={handleNewElem}
				handleDeleteRows={handleDeleteLots}
			/>
		</div>
	);
}
