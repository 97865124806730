import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalEditProjectInformations from './ModalEditProjectInformations';
import { useTranslation } from 'react-i18next';

InformationsGroup.propTypes = {
	data: PropTypes.array.isRequired,
	isPrivate: PropTypes.bool,
};

export default function InformationsGroup({ data, isPrivate = false }) {
	const { t } = useTranslation();

	const [modalEditInformations, setModalEditInformations] = useState(false);

	return (
		<div className={'informationsGroup'}>
			<p className={'editButton hover'} onClick={() => setModalEditInformations(true)}>
				{t('translation.modify')}
			</p>

			{data.map((informationSubGroup, index) => (
				<div className={'informationSubGroup'} key={index}>
					<p className={'nameInformationSubGroup'}>{informationSubGroup.name}</p>
					{informationSubGroup.informations.map((information, index) => (
						<div className={'itemInformation'} key={index}>
							<p className={'labelInformation'}>{information.name}</p>
							<p className={'contentInformation'}>
								{information?.value?.length > 0 ? information.value : '/'}
							</p>
						</div>
					))}
				</div>
			))}

			<ModalEditProjectInformations
				modal={modalEditInformations}
				setModal={setModalEditInformations}
				isPrivate={isPrivate}
			/>
		</div>
	);
}
