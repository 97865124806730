import React from 'react';

export default function ItemActivate({ name, isActive, handleChange }) {
	return (
		<div className={'itemActivate'}>
			<p className={'nameItemActivate'}>{name}</p>
			<div
				style={{
					width: 150,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginLeft: 93,
				}}
				onClick={handleChange}>
				<div className={'choiceAcces'} style={{ backgroundColor: !isActive ? '#eeeeee' : '#64b651' }}>
					<div className={'buttonChoice'} style={{ marginLeft: !isActive ? 2 : 19 }} />
				</div>
			</div>
		</div>
	);
}
