import React from 'react';
import './_contributorsPage.scss';
import ContributorAccountsContainer from './Contributors Accounts Container/ContributorAccountsContainer';
import WidgetSendLink from './Invitation Link Manager/WidgetSendLink';
import { useTranslation } from 'react-i18next';

export default function Contributors({ projectId, projectData, isMandataire, isModeLecture }) {
	const { t } = useTranslation();

	return (
		<div className={'settingsProjet'} style={{ paddingLeft: 30, width: 'calc(100vw - 225px)' }}>
			<p className={'titlePage'}>{t('translation.contributors')}</p>
			<p className={'subtitlePage'}>{t('translation.subtitleContributors')}</p>
			<div className={'bar'} />
			<div className={'contributorsPage'}>
				<div className={'invitationLinkManager'}>
					<WidgetSendLink
						progression={projectData.progression}
						projectName={projectData.name}
						projectImage={projectData.imgUrl}
						isMandataire={isMandataire}
					/>
				</div>

				<ContributorAccountsContainer
					projectId={projectId}
					isMandataire={isMandataire}
					isModeLecture={isModeLecture}
					projectIsActive={projectData.active}
					projectName={projectData.name}
					projectTag={projectData.tag}
					mandataireUid={projectData.creator}
				/>
			</div>
		</div>
	);
}
