import React, { useRef, useEffect, useState } from 'react';
import './_subMenuPage.scss';
import PropTypes from 'prop-types';

SubMenuPage.propTypes = {
	subMenuList: PropTypes.array.isRequired,
	activeSubPage: PropTypes.string.isRequired,
	setActiveSubPage: PropTypes.func.isRequired,
	large: PropTypes.bool,
	ComplementaryComponent: PropTypes.node,
};

export default function SubMenuPage({
	subMenuList,
	activeSubPage,
	setActiveSubPage,
	large = false,
	ComplementaryComponent = null,
}) {
	const [activeWidth, setActiveWidth] = useState(0);
	const [activeOffset, setActiveOffset] = useState(0);

	return (
		<div className={large ? 'largeSubMenuPage' : 'subMenuPage'}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div className={'containerItemsSubMenu'}>
					{subMenuList.map((item, index) => (
						<ItemSubMenu
							key={index}
							item={item}
							isActive={activeSubPage === item.key}
							setActiveOffset={setActiveOffset}
							setActiveWidth={setActiveWidth}
							setActiveSubPage={setActiveSubPage}
						/>
					))}
				</div>

				{ComplementaryComponent && ComplementaryComponent}
			</div>

			<div className="barSubMenuPage">
				<div
					className={'blueBarSubMenu'}
					style={{
						width: activeWidth,
						marginLeft: activeOffset - (large ? 195 : 225),
					}}
				/>
			</div>
		</div>
	);
}

function ItemSubMenu({ item, isActive, setActiveWidth, setActiveOffset, setActiveSubPage }) {
	const spanRef = useRef(null);

	useEffect(() => {
		if (isActive && spanRef.current) {
			const resizeObserver = new ResizeObserver((entries) => {
				for (let entry of entries) {
					const width = entry.target.offsetWidth;
					const offset = entry.target.offsetLeft;
					setActiveWidth(width);
					setActiveOffset(offset);
				}
			});
			resizeObserver.observe(spanRef.current);

			setActiveWidth(spanRef.current.offsetWidth);
			setActiveOffset(spanRef.current.offsetLeft);

			return () => resizeObserver.disconnect();
		}
	}, [isActive, setActiveWidth, setActiveOffset]);

	return (
		<div className={'itemSubMenu'} onClick={() => setActiveSubPage(item.key)}>
			<div
				className={isActive ? 'itemSubMenuNameActive' : 'itemSubMenuName'}
				style={{ color: isActive && '#1C57DD' }}>
				<span ref={spanRef} style={{ display: 'flex' }}>
					{' '}
					{item.icon && (
						<img src={isActive ? item.iconBlue : item.icon} alt="" className={'iconItemSubMenu'} />
					)}
					{item.name}
				</span>
			</div>
		</div>
	);
}

ItemSubMenu.propTypes = {
	item: PropTypes.object.isRequired,
	isActive: PropTypes.bool.isRequired,
	setActiveWidth: PropTypes.func.isRequired,
	setActiveOffset: PropTypes.func.isRequired,
	setActiveSubPage: PropTypes.func.isRequired,
};
