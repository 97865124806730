import React from 'react';
import './_subPageSettingsProject.scss';
import ItemActivate from './components/ItemActivate';
import { useTranslation } from 'react-i18next';
import { useProjectContext } from 'src/contexts/projectContext';
import { firestore } from 'src/firebase/config';

export default function Functionalities() {
	const { t } = useTranslation();

	const [project, setProject] = useProjectContext();

	const dataFunctionalities = [
		{
			name: t('translation.projectsFiances'),
			isActive: !project?.withoutFinance,
			handleChange: async () => {
				setProject({
					...project,
					withoutFinance: !project?.withoutFinance,
				});
				await firestore.doc(`projects/${project.id}`).update({
					withoutFinance: !project?.withoutFinance,
				});
			},
		},
	];

	return (
		<div className={'subPageSettingsProject'}>
			<p className={'titleSubPage'}>{t('translation.projectFunctionalities')}</p>
			<p className={'subtitleSubPage'}>{t('translation.subtitleProjectFunctionalities')}</p>
			{dataFunctionalities.map((data, index) => (
				<ItemActivate key={index} name={data.name} isActive={data.isActive} handleChange={data.handleChange} />
			))}
		</div>
	);
}
