import React, { useContext, useEffect, useState } from 'react';
import { auth, firestore } from '../../../firebase/config';
import 'firebase/firestore';
import 'firebase/storage';
import AlbumsList from './AlbumsList';
import chargement from '../../../assets/chargement.png';
import AgencyContext from '../../../contexts/agencyContext';
import './_photos.scss';
import { MEDIA_COLLECTION, PROJECT_COLLECTION } from '../../../firebase/paths';
import AlbumContent from './Album Content/AlbumContent';
import { useLocation } from 'react-router-dom';
import { useClientsContext } from '../../../contexts/clientsContext';
import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';

PhotosPage.propTypes = {
	type: PropTypes.string.isRequired,
	projectId: PropTypes.string.isRequired,
	projectImgUrl: PropTypes.string.isRequired,
	isFreeTrial: PropTypes.bool.isRequired,
	partnerAgencies: PropTypes.array.isRequired,
	isModeLecture: PropTypes.bool.isRequired,
	handleModifyLots: PropTypes.func.isRequired,
	handleModifyLocations: PropTypes.func.isRequired,
};

export default function PhotosPage({
	type,
	projectId,
	projectImgUrl,
	isFreeTrial,
	partnerAgencies,
	isModeLecture,
	handleModifyLots,
	handleModifyLocations,
}) {
	const { t } = useTranslation();

	const location = useLocation();
	const uid = auth.currentUser?.uid;
	const agencyId = useContext(AgencyContext);
	const [clients] = useClientsContext();

	const [dataLoading, setDataLoading] = useState(true);

	const [albums, setAlbums] = useState([]);
	const [visits, setVisits] = useState([]);

	const [albumSelected, setAlbumSelected] = useState({});
	const [nameAlbumSelected, setNameAlbumSelected] = useState('');

	const [research, setResearch] = useState('');

	//-------------------------------------------------------------------------------------

	useEffect(() => {
		if (location && location.state) {
			if (location.state.selectedAlbumId) {
				firestore
					.collection(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}`)
					.doc(location.state.selectedAlbumId)
					.get()
					.then((doc) => {
						if (doc.exists) {
							const album = { ...doc.data(), id: doc.id };
							handleSelectAlbum(album, location.state.visit ? location.state.visit : false);
						}
					});
			}
		}
	}, [location]);

	useEffect(() => {
		setAlbums([]);
		setVisits([]);
		if (projectId && uid && ((type === 'collaborators' && agencyId) || type === 'clients') && clients) {
			let ref = firestore
				.collection(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}`)
				.where('type', 'in', ['album', 'visit', 'albumMatterport']);
			if (type === 'clients' || clients?.map((item) => item.id).includes(agencyId)) {
				ref = ref.where('clientPermission', '==', true);
			} else if (agencyId) {
				ref = ref.where('permissions', 'array-contains-any', ['all', agencyId]);
			}
			ref.orderBy('orderDate', 'desc')
				.get()
				.then((querySnapshot) => {
					if (querySnapshot) {
						const albums = [];
						const visits = [];
						querySnapshot.forEach((documentSnapshot) => {
							if (documentSnapshot && documentSnapshot.exists) {
								if (documentSnapshot.data().type === 'visit') {
									visits.push({ ...documentSnapshot.data(), id: documentSnapshot.id });
								} else {
									albums.push({ ...documentSnapshot.data(), id: documentSnapshot.id });
								}
							}
						});
						setAlbums((prevState) => [
							...prevState,
							...albums.sort((a, b) => {
								if (a.type === 'albumMatterport' && b.type !== 'albumMatterport') {
									return -1;
								} else if (a.type !== 'albumMatterport' && b.type === 'albumMatterport') {
									return 1;
								} else if (a.type === 'albumMatterport' && b.type === 'albumMatterport') {
									return new Date(b.orderDate) - new Date(a.orderDate);
								} else {
									return 0;
								}
							}),
						]);
						setVisits((prevState) => [...prevState, ...visits]);
						setDataLoading(false);
					}
				});
		}
	}, [projectId, type, uid, agencyId, clients]);

	//-------------------------------------------------------------------------------------

	const handleSelectAlbum = (album, isVisit) => {
		setAlbumSelected(album);
		if (isVisit) {
			setNameAlbumSelected(`${t('common.visit')} ${visits.length - visits.map((it) => it.id).indexOf(album.id)}`);
			mixpanel.track('Visit Opened', {
				'Project ID': projectId,
				Page: 'albums',
			});
		} else {
			setNameAlbumSelected(album.name);
			mixpanel.track('Album Opened', {
				'Project ID': projectId,
				Page: 'albums',
			});
		}
		setDisplayAlbum(false);
		setCountPhoto(true);
	};

	const handleBackToAlbums = () => {
		setDisplayAlbum(true);
		setAlbumSelected({});
		setNameAlbumSelected('');
		setCountPhoto(false);
	};

	//-------------------------------------------------------------------------------------

	const [displayAlbums, setDisplayAlbum] = useState(true);
	const [countPhoto, setCountPhoto] = useState(false);

	const [alreadyClicked, setAlreadyClicked] = useState(false);
	const handleClickPage = () => {
		if (!alreadyClicked) {
			setAlreadyClicked(true);
			mixpanel.track_pageview({
				Page: 'albums',
				'Project ID': projectId,
			});
		}
	};

	return (
		<div onClick={handleClickPage}>
			{!dataLoading ? (
				displayAlbums ? (
					<AlbumsList
						projectId={projectId}
						projectImgUrl={projectImgUrl}
						albums={albums}
						setAlbums={setAlbums}
						visits={visits}
						setVisits={setVisits}
						research={research}
						setResearch={setResearch}
						isFreeTrial={isFreeTrial}
						handleSelectAlbum={handleSelectAlbum}
						isModeLecture={isModeLecture}
					/>
				) : (
					albumSelected &&
					(albumSelected.type === 'visit' ? (
						<AlbumContent
							type={type}
							projectId={projectId}
							projectImgUrl={projectImgUrl}
							setDisplayAlbum={setDisplayAlbum}
							albumSelected={albumSelected}
							setAlbumSelected={setAlbumSelected}
							nameAlbumSelected={nameAlbumSelected}
							setNameAlbumSelected={setNameAlbumSelected}
							countPhoto={countPhoto}
							setCountPhoto={setCountPhoto}
							setAlbums={setVisits}
							setOtherTypeAlbums={setAlbums}
							albumSelectedIndex={visits?.map((it) => it.id).indexOf(albumSelected.id)}
							partnerAgencies={partnerAgencies}
							handleBackToAlbums={handleBackToAlbums}
							isModeLecture={isModeLecture}
							handleModifyLots={handleModifyLots}
							handleModifyLocations={handleModifyLocations}
						/>
					) : (
						<AlbumContent
							type={type}
							projectId={projectId}
							projectImgUrl={projectImgUrl}
							setDisplayAlbum={setDisplayAlbum}
							albumSelected={albumSelected}
							setAlbumSelected={setAlbumSelected}
							nameAlbumSelected={nameAlbumSelected}
							setNameAlbumSelected={setNameAlbumSelected}
							countPhoto={countPhoto}
							setCountPhoto={setCountPhoto}
							setAlbums={setAlbums}
							setOtherTypeAlbums={setVisits}
							albumSelectedIndex={albums?.map((it) => it.id).indexOf(albumSelected.id)}
							partnerAgencies={partnerAgencies}
							handleBackToAlbums={handleBackToAlbums}
							isModeLecture={isModeLecture}
							handleModifyLots={handleModifyLots}
							handleModifyLocations={handleModifyLocations}
						/>
					))
				)
			) : (
				<div className={'chargementContainer'}>
					<img src={chargement} alt="" className={'chargement'} />
				</div>
			)}
		</div>
	);
}
