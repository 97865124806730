import React, { useEffect, useState, useRef } from 'react';
import styles from './TicketSorting.module.css';
import DownGrey from '../../../../../../assets/DownGrey.svg';
import downBlue from '../../../../../../assets/downBlue.svg';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import cross from '../../../../../../assets/blackCrossIcon.svg';
import trash from '../../../../../../assets/trashIcon.svg';
import add from '../../../../../../assets/plusIcon.svg';
import { generateUniqueFirestoreId } from 'src/firebase/utils';

TicketSorting.propTypes = {
	options: PropTypes.array.isRequired,
	selectedOptions: PropTypes.array.isRequired,
	setSelectedOptions: PropTypes.func.isRequired,
	handleClear: PropTypes.func.isRequired,
};

export default function TicketSorting({ options, selectedOptions, setSelectedOptions, handleClear }) {
	const { t } = useTranslation();

	const dropDownRef = useRef(null);
	const addDropDownRef = useRef(null);

	const [dropDownShown, setDropDownShown] = useState(false);
	const [addDropDownShown, setAddDropDownShown] = useState(false);

	const isSelected = selectedOptions?.length > 0;

	useEffect(() => {
		const handleClickOutside = (e) => {
			if (dropDownShown && dropDownRef.current && !dropDownRef.current.contains(e.target)) {
				setDropDownShown(false);
			}
			if (addDropDownShown && addDropDownRef.current && !addDropDownRef.current.contains(e.target)) {
				setAddDropDownShown(false);
			}
		};
		document.addEventListener('click', handleClickOutside);
		return () => document.removeEventListener('click', handleClickOutside);
	}, [dropDownRef, dropDownShown, addDropDownRef, addDropDownShown]);

	return (
		<div ref={dropDownRef}>
			<div className={isSelected ? styles.itemSelected : styles.item} onClick={() => setDropDownShown((p) => !p)}>
				{selectedOptions.length > 1
					? t('translation.sortsWithCount', { count: selectedOptions.length })
					: selectedOptions.length === 1
						? selectedOptions[0].label
						: t('translation.sort')}
				<img
					src={isSelected ? downBlue : DownGrey}
					alt=""
					className={isSelected ? styles.arrowDownSelected : styles.arrowDown}
				/>
			</div>
			<div className={dropDownShown ? styles.dropDown : styles.dropDownHidden}>
				{selectedOptions.length > 0 && (
					<ul className={styles.dropDownList}>
						{selectedOptions.map((option, index) => (
							<li key={option.id}>
								<div className={styles.row}>
									<SortOption
										label={option.label}
										options={options}
										handleSelect={(newOptionKey) => {
											const newOption = options.find((option) => option.key === newOptionKey);
											setSelectedOptions((prev) => {
												const newOptions = [...prev];
												newOptions.splice(index, 1, {
													...newOption,
													order: option.order,
													id: option.id,
												});
												return newOptions;
											});
										}}
									/>
									<SortOption
										label={
											option.order === 'asc'
												? t('translation.ascending')
												: t('translation.descending')
										}
										options={[
											{ label: t('translation.ascending'), key: 'asc' },
											{ label: t('translation.descending'), key: 'desc' },
										]}
										handleSelect={(newOrder) => {
											setSelectedOptions((prev) => {
												const newOptions = [...prev];
												newOptions[index].order = newOrder;
												return newOptions;
											});
										}}
									/>
								</div>
								<div
									className={styles.crossContainer}
									onClick={(e) => {
										e.stopPropagation();
										setSelectedOptions((prev) => {
											const newOptions = [...prev];
											newOptions.splice(index, 1);
											return newOptions;
										});
									}}>
									<img src={cross} alt="" />
								</div>
							</li>
						))}
					</ul>
				)}

				<div className={styles.dropDownFooter} ref={addDropDownRef}>
					<div className={styles.manageSortOption} onClick={() => setAddDropDownShown((p) => !p)}>
						<img src={add} alt="" />
						<p>{t('translation.addSort')}</p>
					</div>
					<div className={addDropDownShown ? styles.littleDropDown : styles.littleDropDownHidden}>
						{options.map((option, index) => (
							<li
								key={index}
								onClick={() => {
									setSelectedOptions((prev) => [
										...prev,
										{ ...option, id: generateUniqueFirestoreId() },
									]);
									setAddDropDownShown(false);
								}}>
								<p>{option.label}</p>
							</li>
						))}
					</div>
					{selectedOptions.length > 0 && (
						<div className={styles.manageSortOption} onClick={handleClear}>
							<img src={trash} alt="" />
							<p>{t('translation.deleteSort')}</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

function SortOption({ label, options, handleSelect }) {
	const [orderDropDownShown, setOrderDropDownShown] = useState(false);
	const orderDropDownRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (e) => {
			if (orderDropDownShown && orderDropDownRef.current && !orderDropDownRef.current.contains(e.target)) {
				setOrderDropDownShown(false);
			}
		};
		document.addEventListener('click', handleClickOutside);
		return () => document.removeEventListener('click', handleClickOutside);
	}, [orderDropDownRef, orderDropDownShown]);

	const handleOrderClick = (newOrder) => {
		setOrderDropDownShown(false);
		handleSelect(newOrder);
	};

	return (
		<div ref={orderDropDownRef}>
			<div className={styles.sortOption} onClick={() => setOrderDropDownShown((p) => !p)}>
				<p>{label}</p>
				<img src={DownGrey} alt="" />
			</div>
			<div className={orderDropDownShown ? styles.littleDropDown : styles.littleDropDownHidden}>
				{options.map((option, index) => (
					<li key={index} onClick={() => handleOrderClick(option.key)}>
						<p>{option.label}</p>
					</li>
				))}
			</div>
		</div>
	);
}

SortOption.propTypes = {
	label: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	handleSelect: PropTypes.func.isRequired,
};
