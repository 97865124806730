import { storage } from './config';

/**
 * Generate a unique id for firestore
 * @returns {string}
 */
export function generateUniqueFirestoreId() {
	const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let autoId = '';
	for (let i = 0; i < 20; i++) {
		autoId += chars.charAt(Math.floor(Math.random() * chars.length));
	}
	return autoId;
}

/**
 * Get the path of a file in firebase storage from an url
 * @param url
 * @returns {string|null}
 */
export function getPathStorageFromUrl(url) {
	if (url) {
		const baseUrl = 'https://firebasestorage.googleapis.com:443/v0/b/opus-f2c9b.appspot.com/o/';
		const baseUrl1 = 'https://firebasestorage.googleapis.com/v0/b/opus-f2c9b.appspot.com/o/';
		const imagePath = url.replace(baseUrl, '');
		let imagePath1 = imagePath.replace(baseUrl1, '');
		const indexOfEndPath = imagePath1.indexOf('?');
		imagePath1 = imagePath1.substring(0, indexOfEndPath);
		imagePath1 = imagePath1.replaceAll('%2F', '/');
		return imagePath1;
	} else {
		return null;
	}
}

/**
 * Delete a file from firebase storage from an url
 * @param url
 */
export function deleteStorageFromUrl(url) {
	if (url) {
		const path = getPathStorageFromUrl(url);
		if (path) {
			storage
				.ref(path)
				.getDownloadURL()
				.then(() => {
					storage
						.ref(getPathStorageFromUrl(url))
						.delete()
						.then((r) => null);
				})
				.catch(() => null);
		}
	}
}

/**
 * Upload a file on firebase storage with retry mechanism and better error handling
 * @param id
 * @param file
 * @param data
 * @param path
 * @param setUploadProgress
 * @param containerName
 * @returns {Promise<string>}
 */
export async function uploadOnStorage(id, file, data, path, setUploadProgress, containerName) {
	const MAX_RETRIES = 3;
	const MAX_DELAY = 1000;
	let attempt = 0;

	const safeSetProgress = (progressData) => {
		if (setUploadProgress) {
			setUploadProgress((prevState) => ({
				...prevState,
				[id]: {
					...data,
					containerName,
					...progressData,
				},
			}));
		}
	};

	const debouncedProgress = debounce((snapshot) => {
		safeSetProgress({
			bytesTransferred: snapshot.bytesTransferred,
			totalBytes: snapshot.totalBytes,
		});
	}, 100);

	const handleError = (error, finalAttempt = false) => {
		console.error(`Upload error (attempt ${attempt + 1}/${MAX_RETRIES}):`, error);
		safeSetProgress({
			error: true,
			errorMessage: error.message,
			finalAttempt,
		});
	};

	while (attempt < MAX_RETRIES) {
		try {
			const storageRef = storage.ref(`${path}/${id}`);
			const uploadTask = storageRef.put(file);

			const uploadResult = await new Promise((resolve, reject) => {
				uploadTask.on(
					'state_changed',
					debouncedProgress,
					(error) => reject(error),
					async () => {
						try {
							const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
							resolve({ snapshot: uploadTask.snapshot, downloadURL });
						} catch (error) {
							reject(error);
						}
					}
				);
			});

			const metadata = await uploadResult.snapshot.ref.getMetadata();
			if (metadata.size === 0) {
				throw new Error('Upload verification failed - file size is 0');
			}

			safeSetProgress({
				completed: true,
				error: false,
				bytesTransferred: uploadResult.snapshot.bytesTransferred,
				totalBytes: uploadResult.snapshot.totalBytes,
			});

			return uploadResult.downloadURL;
		} catch (error) {
			attempt++;
			console.error(`Upload attempt ${attempt} failed:`, error);

			if (attempt === MAX_RETRIES) {
				handleError(error, true);
				throw new Error(`Failed to upload after ${MAX_RETRIES} attempts: ${error.message}`);
			}

			handleError(error);
			await new Promise((resolve) => setTimeout(resolve, MAX_DELAY * attempt));
		}
	}
}

/**
 * Construct a path from an array of strings using '/' as separator
 * @param paths
 * @returns {string}
 */
export function joinPaths(...paths) {
	return paths.join('/');
}

/**
 * Retry a function a certain number of times with a delay between each try
 * @param fn {function} The function to retry
 * @param retries {number} The number of retries
 * @param delay {number} The delay between each try in milliseconds
 * @returns {Promise<*>}
 */
export const retry = async (fn, retries, delay) => {
	try {
		return await fn();
	} catch (error) {
		if (retries > 0) {
			console.warn(`Retrying function ${fn.toString()} in ${delay}ms`);
			await new Promise((resolve) => setTimeout(resolve, delay));
			return retry(fn, retries - 1, delay);
		}
		throw error;
	}
};

// Fonction utilitaire de debounce
function debounce(func, wait) {
	let timeout;
	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
}
