import React, { useEffect, useRef, useState } from 'react';
import styles from './ModalChoiceDuplicate.module.css';
import Modal from 'react-modal/lib/components/Modal';
import crossIcon from '../../../../../../../assets/crossIcon.png';
import { useTranslation } from 'react-i18next';
import blackFiles from '../../../../../../../assets/document.on.document.svg';
import blueFiles from '../../../../../../../assets/document.on.document.blue.svg';
import blackFolder from '../../../../../../../assets/folder.svg';
import blueFolder from '../../../../../../../assets/folder.blue.svg';

export default function ModalChoiceDuplicate({ modal, setModal, onDuplicate, folderName }) {
	const { t } = useTranslation();

	const defaultName = t('translation.nameOfACopy', { name: folderName });

	const [choice, setChoice] = useState('all');
	const [newName, setNewName] = useState(defaultName);

	const inputRef = useRef(null);

	useEffect(() => {
		if (modal) {
			setTimeout(() => {
				inputRef.current?.focus();
			}, 100);
		}
	}, [modal, inputRef]);

	useEffect(() => {
		if (modal) {
			setNewName(defaultName);
		}
	}, [defaultName, modal]);

	const onClose = () => {
		setModal(false);
		setTimeout(() => {
			setChoice('all');
		}, 400);
	};

	const handleDuplicate = () => {
		if (newName.length > 0) {
			onDuplicate(choice, newName);
			onClose();
		}
	};

	return (
		<Modal
			isOpen={modal}
			className={styles.modal}
			overlayClassName={styles.overlayModal}
			closeTimeoutMS={300}
			onRequestClose={onClose}
			shouldCloseOnOverlayClick={true}>
			<img src={crossIcon} className={styles.cross} onClick={onClose} />

			<h2 className={styles.title}>{t('translation.duplicateFolder')}</h2>
			<p className={styles.subtitle}>{t('translation.duplicateFolderDescription', { name: folderName })}</p>

			<div className={styles.newNameContainer}>
				<h3>{t('translation.folderName')}</h3>
				<input
					ref={inputRef}
					type="text"
					value={newName}
					onChange={(e) => setNewName(e.target.value)}
					placeholder={t('translation.name')}
				/>
			</div>

			<div className={styles.choices}>
				<div
					className={choice !== 'foldersOnly' ? styles.choiceSelected : styles.choice}
					onClick={() => setChoice('all')}>
					<img src={choice !== 'foldersOnly' ? blueFiles : blackFiles} alt="" />
					{t('translation.duplicateAllContent')}
				</div>
				<div
					className={choice === 'foldersOnly' ? styles.choiceSelected : styles.choice}
					onClick={() => setChoice('foldersOnly')}>
					<img src={choice === 'foldersOnly' ? blueFolder : blackFolder} alt="" />
					{t('translation.duplicateFoldersOnly')}
				</div>
			</div>

			<div className={styles.submitContainer}>
				<button onClick={handleDuplicate} disabled={newName.length === 0}>
					{t('translation.duplicate')}
				</button>
			</div>
		</Modal>
	);
}
