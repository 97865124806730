import React from 'react';
import './_buttonNew.scss';
import { useTranslation } from 'react-i18next';
import whitePlus from '../../assets/whitePlus.svg';
import PropTypes from 'prop-types';

ButtonNew.propTypes = {
	handleClick: PropTypes.func.isRequired,
};

export default function ButtonNew({ handleClick }) {
	const { t } = useTranslation();

	return (
		<div className={'buttonNew'} onClick={handleClick}>
			<img src={whitePlus} alt="" className={'whitePlus'} />
			{t('translation.new')}
		</div>
	);
}
