import React, { useCallback, useEffect, useRef, useState } from 'react';
import DownGrey from '../../../../../../assets/DownGrey.svg';
import downBlue from '../../../../../../assets/downBlue.svg';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './TicketFilterItem.module.css';
import check from '../../../../../../assets/checkBlanc.png';

TicketFilterItem.propTypes = {
	name: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	selectedOptions: PropTypes.array.isRequired,
	handleSelect: PropTypes.func.isRequired,
	handleClear: PropTypes.func.isRequired,
};

export default function TicketFilterItem({ name, options, selectedOptions, handleSelect, handleClear }) {
	const { t } = useTranslation();

	const dropDownRef = useRef(null);

	const [dropDownShown, setDropDownShown] = useState(false);

	const isSelected = selectedOptions?.length > 0;

	useEffect(() => {
		const handleClickOutside = (e) => {
			if (dropDownShown && dropDownRef.current && !dropDownRef.current.contains(e.target)) {
				setDropDownShown(false);
			}
		};
		document.addEventListener('click', handleClickOutside);
		return () => document.removeEventListener('click', handleClickOutside);
	}, [dropDownRef, dropDownShown]);

	return (
		<div ref={dropDownRef}>
			<div className={isSelected ? styles.itemSelected : styles.item} onClick={() => setDropDownShown((p) => !p)}>
				{name}
				<img
					src={isSelected ? downBlue : DownGrey}
					alt=""
					className={isSelected ? styles.arrowDownSelected : styles.arrowDown}
				/>
			</div>
			<div className={dropDownShown ? styles.dropDown : styles.dropDownHidden}>
				<div className={styles.dropDownHeader}>
					<p>{name}</p>
				</div>
				<ul className={styles.dropDownList}>
					{options.map((option, index) => (
						<>
							<li key={index} onClick={() => handleSelect(option?.key)}>
								<div
									className={
										selectedOptions.includes(option?.key) ||
										option?.subOptions?.every((subOption) =>
											selectedOptions.includes(subOption?.key)
										)
											? styles.checkboxChecked
											: styles.checkbox
									}>
									{selectedOptions.includes(option?.key) ||
									option?.subOptions?.every((subOption) =>
										selectedOptions.includes(subOption?.key)
									) ? (
										<img src={check} alt="" />
									) : null}
								</div>
								{option?.render()}
							</li>
							{option?.subOptions && (
								<div className={styles.subOptions}>
									{option?.subOptions.map((subOption, index) => (
										<li key={index} onClick={() => handleSelect(subOption?.key)}>
											<div
												className={
													selectedOptions.includes(subOption?.key)
														? styles.checkboxChecked
														: styles.checkbox
												}>
												{selectedOptions.includes(subOption?.key) ? (
													<img src={check} alt="" />
												) : null}
											</div>
											{subOption?.render()}
										</li>
									))}
								</div>
							)}
						</>
					))}
				</ul>

				<div className={styles.clearSelectionContainer}>
					<div className={styles.clearSelection} onClick={handleClear}>
						{t('translation.clearSelection')}
					</div>
				</div>
			</div>
		</div>
	);
}
